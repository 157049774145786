/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
import {
  RACButton,
  RACModalCard,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { useHistory } from 'react-router';
import { ChargeOffComponent } from './ChargeOff';
import { agreementContext } from '../../context/PaymentInformationContext';
import { RefundSecurityDeposite } from './RefundSecurityDeposite';
import BackRentComponent from './BackRentPopup';
import { useParams } from 'react-router-dom';
import { rsdObj } from '../../interface/refundSecurityDepositeInterface';
import CONSTANTS from '../../constants/constant';
import { ParamType } from '../../interface/commonInterface';
import { RemainingCreditsInterface } from '../../interface/paymentInfoInterface';

export function OtherIncomeComponent() {
  /**
   * PSC_BR_NO_5
   * The state variable declaratio is done here.
   */
  const classes = takePaymentStyles();
  const history = useHistory();
  const { customerId, agreementId } = useParams<ParamType>();
  const {
    otheIncomeChargeOffDetails,
    otherIncomeDetails,
    featureFlags,
    customerInfo,
    backRentInfo,
    setBackRentInfo,
  } = useContext(agreementContext);

  const [backRentPopup, setBackRentPopup] = useState<boolean>(false);
  const [otherIncomePopupFlag, setOtherIncomePopupFlag] =
    useState<boolean>(false);

  /**PSC_CO_NO_01 to PSC_CO_NO_03
   * State variable declaration used to open and close the popup
   */
  const [chargeOffPopupFlag, setChargeOffPopupFlag] = useState<boolean>(false);

  /**PS_CO_RSD_NO_5
   * State variable declaration for refund security deposit
   * starts here
   */
  const [rsdPopupFlag, setRsdPopupFlag] = useState<boolean>(false);
  const [rsdAgreementInfo, setRsdAgreementInfo] = useState<rsdObj[]>([]);
  let rsdAgreementDetails: rsdObj[] = [];
  /**ends here */

  /**PS_CO_RSD_NO_6 - PS_CO_RSD_NO_11
   * The useffect used to form the Refund Security Deposite agreement object
   * and it is updated to the context.
   */
  useEffect(() => {
    const rsdData =itemCatergoryFilter(CONSTANTS.SECDEP);
    //  otherIncomeDetails?.filter(
    //   (x) =>
    //     x.itemCategory == CONSTANTS.SECDEP &&
    //     Number(x.amount) > CONSTANTS.ZERO_NUMBER
    // );
    if (rsdData && rsdData.length > CONSTANTS.ZERO_NUMBER) {
      for (let i = 0; i < rsdData.length; i++) {
        const obj: rsdObj = {
          agreementId: rsdData[i].otherIncomeInfo?.agreementId,
          agreementNo: rsdData[i].otherIncomeInfo?.agreementNumber,
          depositAmount: Number(
            Number(rsdData[i].amount) + Number(rsdData[i].tax)
          ).toFixed(2),
          amountToCollect: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          totalRefund: Number(
            Number(rsdData[i].amount) + Number(rsdData[i].tax)
          ).toFixed(2),
          description: CONSTANTS.EMPTY_STRING,
        };
        rsdAgreementDetails.push(obj);
      }
    }
    if (customerInfo.rsdAgreementInfo.length > CONSTANTS.ZERO_NUMBER) {
      // Filter rsdAgreementDetails to include items not found in customerInfo.rsdAgreementInfo
      rsdAgreementDetails = rsdAgreementDetails.filter((agreement) => {
        return !customerInfo.rsdAgreementInfo.some(
          (info) => info.agreementId === agreement.agreementId
        );
      });
      rsdAgreementDetails = [
        ...rsdAgreementDetails,
        ...customerInfo.rsdAgreementInfo,
      ];
    }

    setRsdAgreementInfo(rsdAgreementDetails);

    /**PSC_BR_NO_7 - PSC_BR_NO_10
     * The tax and the back rent amount is collected and updated to the context.
     */
    
    const backRentData = itemCatergoryFilter(CONSTANTS.BKRPAY);
    if (backRentData?.length > CONSTANTS.ZERO_NUMBER) {
      const backRentTaxRate = backRentData[0]?.taxRate || CONSTANTS.ZREO_TWO_DECIMAL_STRING;

      // const backRentData = customerInfo.remainingCredits.filter(
      //   (x: RemainingCreditsInterface) =>
      //     x.bucket.toLowerCase() === CONSTANTS.BACK_RENT_LOWER_CASE &&
      //     x.storeNumber == window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
      // );
      const backRentAmount = backRentData.reduce(
        (total, item) => total + Number(item.amount),
        CONSTANTS.ZERO_NUMBER
      );

      const salesTax = Number(backRentAmount) * Number(backRentTaxRate);
      const total = Number(backRentAmount) + Number(salesTax);
      setBackRentInfo({
        ...backRentInfo,
        backRentOwed: Number(backRentAmount).toFixed(2),
        amount: Number(backRentAmount).toFixed(2),
        salesTax: Number(salesTax).toFixed(2),
        taxRate: backRentTaxRate,
        total: Number(total).toFixed(2),
        defaultTotal: Number(total).toFixed(2),
      });
    }
  }, [otherIncomePopupFlag]);
  
  
  const itemCatergoryFilter = (itemCategory: string) => {
    return otherIncomeDetails?.filter(
      (x) => x.itemCategory == itemCategory && Number(x?.amount) > CONSTANTS.ZERO_NUMBER
    );
  }

  const cashSaleRedirection = () => {
    if (customerId) {
      if (Number(agreementId)!=0) {
        history.push({
          pathname: `/payment1/cashSale/${customerId}/${agreementId}`,
        });
      } else {
        history.push({ pathname: `/payment1/cashSale/${customerId}` });
      }
    }
  };

  const rentalItemRedirection = () => {
    if (customerId) {
      if (Number(agreementId)!=0) {
        history.push({
          pathname: `/payment1/rentalitemsale/${customerId}/${agreementId}`,
          state: {
            editNo: 0,
            editIndex: ''
          },
        });
      } else {
        history.push({
          pathname: `/payment1/rentalitemsale/${customerId}`,
          state: {
            editNo: 0,
            editIndex: ''
          },
        });      
      }
    }  }

  /**
   * PSC_BR_NO_12
   * The renderOtherIncomeModal() method is used to render the other Income popup.
   * @returns
   */
  const renderOtherIncomeModal = () => {
    return (
      <Grid item>
        <Typography
          className={`${classes.floatLeft} ${classes.modalTitle} ${classes.bold} ${classes.ps3}`}
        >
          Sales
        </Typography>
        <Grid container spacing={2} className={classes.px2}>
          <Grid item md={4} className={classes.mb2}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color='primary'
              variant='contained'
              data-testid='rentalItemSale_id'
              disabled={Number(featureFlags?.RentalItemSale)==1?false:true}
              onClick={() => {
                rentalItemRedirection()
              }}
            >
              {CONSTANTS.RENTAL_ITEM_SALE}
            </RACButton>
          </Grid>
          <Grid item md={6} className={classes.mb2}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color='primary'
              variant='contained'
              data-testid='cashSale'
              disabled={Number(featureFlags?.CashSale)==1?false:true}
              onClick={() => cashSaleRedirection()}
            >
              {CONSTANTS.NON_INVENTORY_SALE}
            </RACButton>
          </Grid>
        </Grid>
        <Typography
          className={`${classes.floatLeft} ${classes.modalTitle} ${classes.bold} ${classes.ps3}`}
        >
          Payments
        </Typography>
        <Grid container spacing={2} className={classes.px2}>
          {/**
           * PSC_BR_NO_13
           * The Back Rent button is rendered based on the config value.
           * And if back rent amount is present then the backRent button is enabled
           */}

          <Grid item md={3} className={`${classes.mb2} ${classes.pd6}`}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color='primary'
              variant='contained'
              data-testid='backRent_id'
              disabled={Number(featureFlags.BackRent) == CONSTANTS.ONE_NUMBER && Number(backRentInfo?.backRentOwed) > 0 ? false : true}
              onClick={() => {
                setOtherIncomePopupFlag(false);
                setBackRentPopup(true);
              }}
            >
              {CONSTANTS.BACK_RENT}
            </RACButton>
          </Grid>

          {/**PSC_CO_NO_10 - PSC_CO_NO_11
           * If the charge-off agreements is present then the button is enabled and by clicking the button the popup is opend.
           */}

          <Grid item md={7} className={`${classes.mb2} ${classes.pd6}`}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color='primary'
              variant='contained'
              data-testid='chargeOffId'
              disabled={Number(featureFlags?.ChargeOffSkipStolen) == CONSTANTS.ONE_NUMBER && otheIncomeChargeOffDetails?.length > 0 ? false : true}
              onClick={() => {
                setOtherIncomePopupFlag(false);
                setChargeOffPopupFlag(true);
              }}
            >
              {CONSTANTS.CHARGEOFF_SKIP_STOLEN}
            </RACButton>
          </Grid>

          {/**PS_CO_RSD_NO_14
           * The Refund Security Deposite button is rendered based on the feature flag
           * And if refund deposite amount is present then the Refund Security Deposite button is enabled.
           */}

          <Grid item md={6} className={`${classes.mb2} ${classes.pd6}`}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color='primary'
              variant='contained'
              disabled={Number(featureFlags.RefundSecurityDeposit) ==
                CONSTANTS.ONE_NUMBER && rsdAgreementInfo.length > 0 ? false : true}
              onClick={() => {
                setOtherIncomePopupFlag(false);
                setRsdPopupFlag(true);
              }}
            >
              {CONSTANTS.REFUND_SECURITY_DEPOSITE}
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Grid
        item
        className={`${classes.btngroup} ${classes.floatLeft}`}
        role='group'
        style={{ display: 'flux' }}
      >
        <RACButton
          className={classes.ms2}
          type='button'
          color='primary'
          variant='contained'
          data-testid='otherIncome_id'
          onClick={() => setOtherIncomePopupFlag(true)}
        >
          Other Income
        </RACButton>

        <RACModalCard
          isOpen={otherIncomePopupFlag}
          maxWidth='sm'
          title={'Other Income'}
          closeIcon={true}
          borderRadius={'25px !important'}
          onClose={() => setOtherIncomePopupFlag(false)}
          // eslint-disable-next-line react/no-children-prop
          children={renderOtherIncomeModal()}
        />
      </Grid>
      {/**PSC_CO_NO_12
       * The flag used to open the charge-off popup is sent as props.
       */}
      {chargeOffPopupFlag ? (
        <ChargeOffComponent
          isOpen={chargeOffPopupFlag}
          customerId={customerId}
          isClose={(res: boolean) => setChargeOffPopupFlag(res)}
        />
      ) : null}
      {/**
       * PS_CO_RSD_NO_16: The <RefundSecurityDeposite/> component is declared which helps to render the
       * Refund Security Deposite popup.
       */}
      {rsdPopupFlag ? (
        <RefundSecurityDeposite
          isOpen={rsdPopupFlag}
          setRsdPopupFlag={setRsdPopupFlag}
          rsdAgreementInfo={rsdAgreementInfo}
          onClose={(res) => setRsdPopupFlag(res)}
        />
      ) : null}

      {backRentPopup ? (
        <BackRentComponent
          isOpen={backRentPopup}
          onClose={(res) => setBackRentPopup(res)}
          customerId={customerId}
          // backRentInfo={backRentInfo}
        />
      ) : null}
    </>
  );
}
