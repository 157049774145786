/* eslint-disable react/no-children-prop */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable no-constant-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable sonarjs/no-inverted-boolean-check */


import React, { useState, useEffect, useRef, useContext } from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import {
  AcceptPaymentApi,
  AgreementCustomer,
  GenerateAgreementDoc,
  SalesLead,
  Receiptpdfgen,
  GetEmployeeID,
  GetRole,
  MakePayment,
  LDwServiceCall,
  ProcessPaymentAPI,
  ActiveClub,
  PolicyServiceCall,
  getDocument,
  PlaceOrderCO,
  postAgreementActivity,
  storeException,
  UnEnrollAutoPay
} from '../../api/user';
import { MakePaymentDTO, CustomerInfo } from '../../domain/makepaymentpayload';
import moment from 'moment';
import SecondFactor from './TwoFactorPopup';
import { salesLeadRequest } from '../microfrontends/paymentgrid';
import { CustomerdataContext } from '../../context/customer/customerdata';
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import StoreException from './StoreException';
import { useParams } from 'react-router-dom';
import {
  RACButton,
  RACModalCard,
  Grid,
  Typography,
  RACCOLOR,
  makeStyles,
  RACModal,
  RACTable,
  RACTableRow,
  RACTableCell,
  CircularProgress,
  TextareaAutosize,
} from '@rentacenter/racstrap';
import { AcceptPaymentRequest } from '../../racstrapComponents/microfrontends/paymentgrid';
import { height } from '@mui/system';
import { ReactComponent as FailIcon } from '../../assets/images/contest-close.svg';
import { ReactComponent as TransactionSuccessIcon } from '../../../src/assets/images/success-icon-print.svg';
let choosenReceiptIndex: any = 0;
import { Redirect, useLocation } from 'react-router-dom';
import { AppRoute } from '../../config/route-config';
import { DeliveryContext } from '../../context/DeliveryContext';
import CustomerSignature from './CustomerSignature';
import { takePaymentStyles } from '../../stylesJS/makeStyles';

const useClasses = makeStyles((theme: any) => ({
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racErrorSessionFunction: {
    fontFamily: 'OpenSans-bold',
    fontSize:'16px',
    marginBottom: '8px',
    marginTop: '10px',
  },
  me2: {
    marginRight: theme.typography.pxToRem(8),
  },
  bold: {
    fontFamily: 'OpenSans-bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  mt4: {
    marginTop: theme.typography.pxToRem(24),
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  textcenter: {
    textAlign: 'center',
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  modalfooter: {
    borderTop: '0px solid transparent',
  },
  w100: {
    width: '100%',
  },
  ms1: {
    marginLeft: theme.typography.pxToRem(4),
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  colmd12: {
    flex: '0 0 auto',
    width: '100%',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  popUpTxtStyle: {
    fontFamily: 'OpenSans-regular',
    fontSize: '16px',
  },
  popUpTxtStyleSession: {
    fontFamily: 'OpenSans-regular',
    fontSize: '14px',
  },
  me3: {
    marginRight: theme.typography.pxToRem(16),
  },
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0`,
    height: '90%',
    overflow: 'hidden',
  },
  dialogRoot: {
    height: '90% !important',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      height: '90% !important',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
  },
  paymentmodal: {
    maxHeight: '95% !important',
    height: '95% !important',
  },
  textend: {
    textAlign: 'end',
  },
  customModal: {
    '& div': {
      '& div': {
        maxWidth: '2000px !important',
      },
    },
  },

  mb32: {
    marginBottom: theme.typography.pxToRem(32),
  },

  receiptLoader: {
    width: '25px !important',
    height: '25px !important',
  },
  titletxtstyle: {
    fontSize: theme.typography.pxToRem(18),
  },
  racGrid: {
    '& th': {
      fontFamily: 'OpenSans-semibold',
      whiteSpace: 'nowrap',
      padding: '0.9375rem 0.2rem',
      color: '#000000',
      fontSize: '14px',
    },
    '& td': {
      padding: '0.375rem 0.2rem',
      fontFamily: 'OpenSans-semibold',
      color: '#5A6495',
      fontSize: '14px',
    },
  },
  me1: {
    marginRight: theme.typography.pxToRem(4),
  },
  foc: {
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '1px 1px 6px 3px #80bdff',
    },
  },
  textAreaN: {
    margin: '10px 0px',
    width: 500,
    padding: '6PX',
    borderRadius: '4px',
    resize: 'none',
  },
  description: {
    margin: '10px 0px',
  },
  me2N: {
    marginRight: '0.5rem !important',
    marginLeft: '0.5rem',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
}));
let extensionArrayUrl: string[] = [];
let cofArrayUrl: string[] = [];
interface ParamType {
  customerId: string;
  agreementId: string;
}
import { userTraceContextData } from "../../context/UserTraceContext";
import { cappedFreeTimeValidation } from './customer validation/CappedFreeTimeValidation';
import { customerStyles } from '../../stylesJS/customerStyles';
import { SLA_MODULE_NAME } from '../../constants/constants';
export function ProcessPayment(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;


  const { StoreExceptionPayload, setStoreExceptionPayload, approverId } = useContext(CustomerdataContext)

  const { customerId } = useParams<ParamType>();
  const urlParams = new URLSearchParams(window.location.search);
  const retentionType = urlParams.get('CRTYPE');
  const {
    clubsStatus,
    customerOrder,
    // searchResult
  } = useContext(DeliveryContext);
  const [clubBtnStatus, setclubBtnStatus] = clubsStatus;
  const [custOrder, setCustOrder] = customerOrder;
  const classes = useClasses();
  const classes2 = takePaymentStyles();
  const classesTwo = customerStyles();
  const history = useHistory();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [tran, settran] = useState(false);
  const [recpt, setrecpt] = useState(false);
  const [load, setload] = useState(false);
  const [Tray1, setTray1] = useState(false);
  const [initialReceiptStatus, setInitialReceiptStatus] = useState();
  const [finalPaymentResult, setFinalPaymentResult] = useState();
  const [Ownership, setOwnership] = useState(false);
  const [cofUrlState, setcofUrlState]: any = useState();
  const [Recptload, setRecptload] = useState(false);
  const [receipturl, setreceipturl]: any = useState(undefined);
  const [cofPopup, setcofPopup]: any = useState(false);
  const [extPopup, setextPopup]: any = useState(false);
  const [viewReceiptPopup, setviewReceiptPopup]: any = useState(false);
  const [viewExtPopup, setviewExtPopup]: any = useState(false);
  const [viewCOFPopup, setviewCOFPopup]: any = useState(false);
  const [cofIndex, setcofIndex]: any = useState(0);
  const [extIndex, setextIndex]: any = useState(0);
  let [StoreExceptionEnable, setStoreExceptionEnable] = useState(false)
  let [EPOException, setEPOException] = useState(false)
  let [freeDaysException, setfreeDaysException] = useState(false)
  let [rentalExtensionAgr, setRentalExtensionAgr] = useState<any>([])



  const [MakepaymentResp, setMakepaymentResp]: any = useState();
  const [txtChange, settxtChange] = useState(false);
  const [receiptID, setReceiptID] = useState('');
  const [epayFail, setepayFail] = useState(false);
  const [cardPaymentFailedMessage, setCardPaymentFailedMessage] = useState('');
  const [showPaymentStatus, setShowPaymentStatus]: any = useState(false);
  const [showReceiptStatus, setShowReceiptStatus]: any = useState(false);
  const [receiptResultArray, setreceiptResultArray]: any = useState([]);
  const [transactionResultArray, settransactionResultArray]: any =
    useState<any>([]);
  /** RIS_PC_NO_34,RIS_PC_NO_35,RISPC_NO_36 Rental Item Sale state variables*/
  const [largepaymentAlertpopupFlag, setlargepaymentAlertpopupFlag] =
    useState(false);
  const [reasonpopupFlag, setReasonpopupFlag] = useState(false);
  const [risFlag, setRisFlag] = useState<boolean>(false);
  const [reason, setReason] = useState<any>({ reason: '' });
  const [customerPresentpopupFlag, setcustomerPresentpopupFlag] =
    useState(false);
  const [changepopupFlag, setChangepopupFlag] = useState(false);
  let receptFailedAttempts = 0;
  let coReceiptFailedAttempt = 0;
  let collectStoreNumber: any = [];
  const totalDueamt = props.Totaldue;
  const getcardResponse = props.getResponse;
  const radioFlags = props.WidgetRadioBtn;
  const empId = props.coworkerId;
  const cardResponse = props.getCardstate;
  let AcPayment = props.AcceptPay;
  let ClubAcPayment = props.ClubPay;
  let ReceiptCatogory = props.Reciept;
  console.log(
    AcPayment,
    ClubAcPayment,
    ReceiptCatogory,
    'receipt array details'
  );
  const Agreement = props.AgreementIdProp;
  const customer = props.CustomerIdProp;
  const Changes = props.Paymentchange;
  const PaymentOrigin = props.PayOrigin;
  const getPaymentInfoResp = props.Taxfield;
  const acceptdisable = props.disabled;
  const cardAuthNo = props.cardAuthNumber;
  const lastFour = props.lastFour;
  const swipeCardAmt = props.swipeCardAmt;
  let finalagr = props.finalagr;
  const swipeFlag = props.swipeFlag;
  let actLDWArr = props.actLDWArr;
  let actPolicyArr = props.actPolicyArr;
  const cardDetail = props.cardDetail;
  const checkDetail = props.checkDetail;
  const taxGeoCode = props.taxGeoCode;
  const taxZip = props.taxZip;
  const origin = props.origin;
  const convTax = props.convTax;
  const profileAccResp = props.profileAccResp;
  const cardToken = props.cardToken;
  const chargeCardArray = props.chargeCard;
  const carryAmount = props.carryRent;
  const tenderTypeArray = props.tenderTypeArray;
  // const carryTaxRate=
  //let paymentMade = props.paymentMade;
  const billingAddressCharge = props.billingAddressCharge;
  const avaliableCredits = props.avaliableCredits;
  console.log(props, 'props in accept payment');

  //Below state variables are declared to handle authentication popup flow
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState();
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [secondFactorAuth, setSecondFactorAuth] = useState(false);
  const [CurrentRole, setCurrentRole] = useState('');
  const [AgreementIndex, setAgreementIndex]: any = useState();
  const [TranFail, setTranFail] = useState(false);
  const [isAmountMismatch, setIsAmountMismatch] = useState(false);
  const [processPaymentInfo, setProcessPaymentInfo] = useState(false);
  const [fetchReceipt, setFetchReceipt] = useState(false);
  const [TranFailtxtchange, setTranFailtxtchange] = useState(false);
  const [ReceiptURL, setReceiptURL] = useState<any>();
  const [ExtensionURL, setExtensionURL] = useState<any>();
  const [COFUrl, setCOFUrl] = useState<any>([]);
  const [openCommitmentConfirmation, setopenCommitmentConfirmation] =
    useState(false);

  const eventFrame = useRef<HTMLIFrameElement>(null);

  const [redirectToAccountManagement, setRedirectToAccountManagement] =
    useState(false);
  const [customerSignaturePopup, setcustomerSignaturePopup] =
    useState<any>(false);
  const [customerSignaturePopupInote, setcustomerSignaturePopupInote] =
    useState<any>(false);
  const [clubdocumentgenerate, setclubdocumentgenerate] = useState<any>(false);
  const [clubSignFlow, setclubSignFlow] = useState<any>(false);
  const [ReceiptURLArray, setReceiptURLArray] = useState<any>([]);
  const [receipturlCO, setreceipturlCO]: any = useState();
  const [recptCO, setrecptCO] = useState(false);
  const [selectedIndex, setSelectedIndex]: any = useState();
  const [viewReceiptPopupCO, setviewReceiptPopupCO]: any = useState(false);
  const [openSuspenseWithdraw, setOpenSuspenseWithdraw] = useState(false);
  const [sessionExpiredPopupFlag,setSessionExpiredPopupFlag] = useState<any>(false);
  const [freeTimeAuthPopup, setFreeTimeAuthPopup] = useState(false);
  const [cappedFreeTimeAuthCompleted, setCappedFreeTimeAuthCompleted] = useState(true);
  const [proceedButtonLoader, setProceedButtonLoader] = useState(false);
  const [autoPayConfirmationPopup, setAutoPayConfirmationPopup] = useState<boolean>(false);//RAC2
  const exchangeAgreementId: any = urlParams.get('exchangeAgreementId');
  const location = useLocation<any>();
  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];
  const [pifAgreementID, setPifAgreementID] = useState<any>('');
  useEffect(() => {
    const GettingRole = async () => {
      if (containerData != undefined) {
        const GetMenuDetails: any = containerData.GetRole();
        console.log('GetMenuDetails', GetMenuDetails);
        // const GetMenuDetailsResponse = GetMenuDetails.data;
        const currentRole = GetMenuDetails;
        console.log('currentRole', currentRole);
        setCurrentRole(currentRole);
      }
      // if (GetMenuDetails.status === 200) {

      // }
      // const GetcurrentUser = await GetEmployeeID();
      // console.log('GetcurrentUser', GetcurrentUser);
      // if (GetcurrentUser.status === 200) {
      //   const GetcurrentUserResponse = GetcurrentUser.data;
      //   const EmployeeId = GetcurrentUserResponse.employeeId;
      //   const GetMenuPayload = {
      //     coworkerId: EmployeeId,
      //   };

      // }
    };
    GettingRole();
  }, [containerData]);



  const storeExceptionTrigger = async () => {
    let storeExceptionRequestArr: any = [];
    const selectedRentalAgreementsFreeTime = props?.selectedAgreements?.filter(
      (el) =>
        el.Agreementtype !== 'Club' &&
        !el.Agreementtype.includes('Cell phone') &&
        !el.Agreementtype.includes('Restitution') &&
        el.otherIncomeType != 'Rental Item Sale' &&
        el.otherIncomeType != 'NSF Check' &&
        el.otherIncomeType != 'Credit Card Charge Back' &&
        el.otherIncomeType != 'Back Rent' &&
        el.otherIncomeType != 'Charge-off' &&
        el.otherIncomeType != 'Xbox Gift Card' &&
        el.otherIncomeType != 'Xbox Gift Membership' &&
        el.otherIncomeType != 'Non Inventory Sale' &&
        el.FreeTime &&
        el.FreeTime?.freeTimeType != 'PROMO'
    );
    const selectRentalEpoDiscoutAgreement = props?.selectedAgreements?.filter(
      (el) =>
        el.Agreementtype !== 'Club' &&
        !el.Agreementtype.includes('Cell phone') &&
        !el.Agreementtype.includes('Restitution') &&
        el.otherIncomeType != 'Rental Item Sale' &&
        el.otherIncomeType != 'NSF Check' &&
        el.otherIncomeType != 'Credit Card Charge Back' &&
        el.otherIncomeType != 'Back Rent' &&
        el.otherIncomeType != 'Charge-off' &&
        el.otherIncomeType != 'Xbox Gift Card' &&
        el.otherIncomeType != 'Xbox Gift Membership' &&
        el.otherIncomeType != 'Non Inventory Sale' &&
        el.acceptEpoFlag == 1 &&
        ((el.isAcceptEPODiscounts?.epoDiscount && el.isAcceptEPODiscounts?.epoDiscount != '0.00') || (el.isAcceptEPODiscounts?.pastDueDiscount && el.isAcceptEPODiscounts?.pastDueDiscount != '0.00') || (el.isAcceptEPODiscounts?.noteDiscount && el.isAcceptEPODiscounts?.noteDiscount != '0.00'))
    );
    if (selectedRentalAgreementsFreeTime?.length > 0) {
      for (let i = 0; i < selectedRentalAgreementsFreeTime.length; i++) {
        selectedRentalAgreementsFreeTime[i].exceptionType = 'REX'
        storeExceptionRequestArr.push(selectedRentalAgreementsFreeTime[i])
        setRentalExtensionAgr(selectedRentalAgreementsFreeTime)
      }
    }
    if (selectRentalEpoDiscoutAgreement?.length > 0) {
      for (let i = 0; i < selectRentalEpoDiscoutAgreement.length; i++) {
        selectRentalEpoDiscoutAgreement[i].exceptionType = 'EPD'
        storeExceptionRequestArr.push(selectRentalEpoDiscoutAgreement[i])
      }
    }
    // debugger
    return storeExceptionRequestArr?.length > 0 ? storeExceptionRequestArr : []
  }

  useEffect(() => {
    if (TwoFactorCompleted === true) {

      setSecondFactorOpen(false);
      extensionArrayUrl = [];
      cofArrayUrl = [];

      const triggerStoreException = async () => {
        try {
          const storeExceptionTriggerRes = await storeExceptionTrigger();
          // debugger
          if (storeExceptionTriggerRes?.length > 0) {
            if (storeExceptionTriggerRes?.some((obj) => obj.exceptionType == 'EPD')) {
              const EPODiscountArray = storeExceptionTriggerRes?.filter((obj) => obj?.exceptionType == 'EPD')
              if (storeExceptionTriggerRes?.some((obj) => obj?.exceptionType == 'REX')) {
                setfreeDaysException(true);
              }
              EPOException = true
              setEPOException(EPOException);
              setStoreExceptionEnable(true);
            } else if (storeExceptionTriggerRes?.some((obj) => obj?.exceptionType == 'REX')) {
              setfreeDaysException(true);
              setStoreExceptionEnable(true);
            } else {
              setload(true);
              if (props.isOtherStoreAgrSelected) {
                invokeAcceptPayAPIMultiStore();
              } else {
                invokeAcceptPayAPISingleStore();
              }
            }
          }
          else {
            setload(true);
            if (props.isOtherStoreAgrSelected) {
              invokeAcceptPayAPIMultiStore();
            } else {
              invokeAcceptPayAPISingleStore();
            }
          }

        } catch (error) {
          // Handle error
          setload(true);
          console.error(error);
          if (props.isOtherStoreAgrSelected) {
            invokeAcceptPayAPIMultiStore();
          } else {
            invokeAcceptPayAPISingleStore();
          }
        }
      }

      triggerStoreException();
    } else if (TwoFactorCancelClick === true) {
      setload(false);
      setSecondFactorOpen(false);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TwoFactorCancelClick, TwoFactorCompleted, EmployeeDetails, cappedFreeTimeAuthCompleted]);

  let AcceptMiscArray: any = [];
  let receiptCatArray: any = [];

  const invokeAcceptPayAPISingleStore = async (updatedPayload?: any) => {
    console.log("userTraceData", userTraceData)
    let cardData: any = [];
    props?.setpaymentMadeOptimized(true);
    cardData = getcardResponse.CardInfo.filter(
      (e) => Number(e.Amount) > 0 && e.cvvEncrypt.length >= 3
    );

    if (chargeCardArray.length > 0) {
      cardData = [...cardData, ...chargeCardArray];
    }

    let processOrderData: any = undefined;
    if (
      props.isCoPrelim &&
      (props.provideEAFlag == null || props.provideEAFlag == 'No')
    ) {
      processOrderData = {
        eaFlag: props.provideEAFlag,
        agreementNumber: props.provideAgrNumberPO,
      };
    } else if (props.isCoPrelim && props.provideEAFlag == 'Yes') {
      const updatedPlaceOrderAddressData = props.providePOAddressData;
      // updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
      processOrderData = {
        eaFlag: props.provideEAFlag,
        agreementNumber: props.provideAgrNumberPO,
        shippingAddress: updatedPlaceOrderAddressData,
        items: props.providePlaceOrderItemData
      };
    }
    const entireAcceptPayAPIArray: any = [];
    let creditOnAccount: any = [];
    let IPSuspense: any = [];
    let webleadAmount: any = [];
    let COAAmount = 0;
    let overPaymentSuspenseAmount = 0;
    let changeNum = Number(Number(Changes).toFixed(2));
    let clubData: any = [];
    let nonClubData: any = [];
    let miscellaneousData: any = [];
    const clubPayload: any = [];
    const transferSuspensePayload: any = [];
    const agreementPaymentPayload: any = [];
    const miscPaymentPayload: any = [];
    const tenderDetailsPayload: any = [];
    const cardDetailsPayload: any = [];
    const customerAccountPayload: any = [];
    const changeAmountPayload: any =
      radioFlags == '0' ? Number(Number(fixedChange(Changes)).toFixed(2)) : 0.0;

    clubData = props.selectedAgreements.filter(
      (el) => el.Agreementtype === 'Club'
    );
    nonClubData = props.selectedAgreements.filter(
      (el) =>
        el.Agreementtype !== 'Club' &&
        !el.Agreementtype.includes('Cell phone') &&
        !el.Agreementtype.includes('Restitution') &&
        el.otherIncomeType != 'Rental Item Sale' &&
        el.otherIncomeType != 'NSF Check' &&
        el.otherIncomeType != 'Credit Card Charge Back' &&
        el.otherIncomeType != 'Back Rent' &&
        el.otherIncomeType != 'Charge-off' &&
        el.otherIncomeType != 'Xbox Gift Card' &&
        el.otherIncomeType != 'Xbox Gift Membership' &&
        el.otherIncomeType != 'Non Inventory Sale'
    );
    miscellaneousData = props.selectedAgreements.filter(
      (el) =>
        el.Agreementtype.includes('Cell phone') ||
        el.Agreementtype.includes('Restitution') ||
        el.otherIncomeType == 'Rental Item Sale' ||
        el.otherIncomeType == 'NSF Check' ||
        el.otherIncomeType == 'Credit Card Charge Back' ||
        el.otherIncomeType == 'Back Rent' ||
        el.otherIncomeType == 'Charge-off' ||
        el.otherIncomeType == 'Xbox Gift Card' ||
        el.otherIncomeType == 'Xbox Gift Membership' ||
        el.otherIncomeType == 'Non Inventory Sale'
    );
    console.log('clubData logger', clubData);
    console.log('nonClubData logger', nonClubData);
    console.log('miscellaneousData logger', miscellaneousData);

    if (props.availableCredsremaining.length > 0) {
      webleadAmount = props.availableCredsremaining.filter(
        (x) => x.balanceType === 'WEBLEAD'
      );
      creditOnAccount = props.availableCredsremaining.filter(
        (x) => x.balanceType === 'COA'
      );
      IPSuspense = props.availableCredsremaining.filter(
        (x) => x.balanceType === 'IP Suspence'
      );
    }

    if (
      radioFlags == '1' &&
      Number(Changes) > 0 &&
      props.suspensehide == false &&
      props.CreditRadio == true
    ) {
      COAAmount = radioFlags == '1' && changeNum >= 0 ? changeNum : 0;
    } else if (
      radioFlags == '1' &&
      Number(Changes) > 0 &&
      props.suspensehide == true
    ) {
      overPaymentSuspenseAmount =
        radioFlags == '1' && changeNum >= 0 ? changeNum : 0;
    }

    if (
      creditOnAccount.length > 0 &&
      Number(creditOnAccount[0].accountBalance) > 0
    ) {
      customerAccountPayload.push({
        actionType: 'D',
        accountType: 'COA',
        storeNumber: String(window.sessionStorage.getItem('storeNumber')),
        amount: Number(creditOnAccount[0].accountBalance).toFixed(2),
      });
    }

    if (
      webleadAmount.length > 0 &&
      1 * Number(webleadAmount[0].accountBalance) > 0
    ) {
      customerAccountPayload.push({
        actionType: 'D',
        accountType: 'WEBLEAD',
        storeNumber: String(window.sessionStorage.getItem('storeNumber')),
        amount: (1 * Number(webleadAmount[0].accountBalance)).toFixed(2),
      });
    }

    if (IPSuspense.length > 0 && 1 * Number(IPSuspense[0].accountBalance) > 0) {
      customerAccountPayload.push({
        actionType: "D",
        accountType: "SIPS",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: (1 * Number(IPSuspense[0].accountBalance)).toFixed(2),
      });
    }

    if (Number(COAAmount) > 0) {
      customerAccountPayload.push({
        actionType: 'C',
        accountType: 'COA',
        storeNumber: String(window.sessionStorage.getItem('storeNumber')),
        amount: Number(COAAmount).toFixed(2),
      });
    }

    if (Number(overPaymentSuspenseAmount) > 0) {
      customerAccountPayload.push({
        actionType: 'C',
        accountType: 'SUSP',
        storeNumber: String(window.sessionStorage.getItem('storeNumber')),
        amount: Number(overPaymentSuspenseAmount).toFixed(2),
      });
    }

    if (Number(carryAmount) > 0) {
      customerAccountPayload.push({
        actionType: 'C',
        accountType: 'CR',
        storeNumber: String(window.sessionStorage.getItem('storeNumber')),
        amount: Number(carryAmount).toFixed(2),
      });
    }

    if (cardData.length) {
      for (let i = 0; i < cardData.length; i++) {
        const cardtoken: any =
          cardToken.length == 0
            ? ''
            : cardToken.filter(
              (e) => String(cardData[i].lastFour) == String(e.lastFour)
            );
        cardDetailsPayload.push({
          amount: Number(cardData[i].Amount).toFixed(2),
          authNumber: '',
          cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
          cardLastFour: cardData[i].lastFour,
          cardType: cardData[i].cardRef,
          cardTransactionType:
            cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
          externalTransactionId: '',
          paymentId: cardData[i].paymentId,
          expirationDate: cardData[i].expirationDate,
          paymentToken:cardData[i].cardToken,
          billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
        });
      }
    }
    if (cardDetail.length) {
      cardDetail.map((el) => {
        cardDetailsPayload.push({
          amount: Number(el.cardAmount).toFixed(2),
          authNumber: String(el.cardAuthNumber),
          cardLastFour: el.cardLastFour,
          cardType: el.cardType == undefined ? 'U' : el.cardType,
          cardTransactionType: 'SWP',
          externalTransactionId: String(el.cardSequence),
          paymentToken: el.cardToken !== undefined ? el.cardToken : '',
        });
      });
    }
    console.log('cardDetailsPayload logger', cardDetailsPayload);

    let cashArray = props?.tendertypeglobalarray?.filter(x=>x?.tendertype=='Cash')
    console.log('cardDetailsPayload logger', cashArray);
    if (Number(cashArray[0]?.Amount) > 0) {
      tenderDetailsPayload.push({
        type: 'CASH',
        amount: Number(cashArray[0]?.Amount).toFixed(2),
      });
    }
    if (checkDetail.length) {
      checkDetail.map((el) => {
        if (el.Type_info == 'Check') {
          tenderDetailsPayload.push({
            type: 'CHK',
            amount: el.Amount_rac,
            tenderNumber: el.check_no,
            checkType: el.check_type,
            checkWrittenBy: el.Written_by,
            checkWrittenTo: el.Written_too,
          });
        } else if (el.Type_info == 'Money Order') {
          tenderDetailsPayload.push({
            type: 'MO',
            amount: el.Amount_rac,
            tenderNumber: el.check_no,
            purchaser: el.Written_by,
            issuer: el.Written_too,
          });
        }
      });
      console.log('checkDetail data from array', checkDetail);
    }
    if (Number(changeAmountPayload) > 0) {
      tenderDetailsPayload.push({
        type: 'CHG',
        amount: Number(changeAmountPayload).toFixed(2),
      });
    }
    if (clubData.length) {
      clubData.map((el) => {
        clubPayload.push({
          customerClubId: String(el.customerClubId),
          isZeroPayment: el.isZeroPayment == true || el?.isReturnAgrFlag == 1 ? 'Y' : undefined,
          editedSchedule: el.editSchedule.length ? el.editSchedule : undefined,
          adjustedDueDate: el.dueDateModified
            ? moment(el.Nextduedate).format('YYYY-MM-DD')
            : undefined,
        });
      });
    }
    if (props.donationAmount != undefined) {
      if (props?.donationAmount?.respFlag && props?.donationAmount?.amount != "0.00"&&props?.donationBtnFlag) {
        let reason: any = ""
        switch (props?.donationAmount?.type) {
          case 'BGC':
            reason = "Boys and Girls Club";
            break;
          case 'STON':
            reason = "Fill The Fridge";
            break;
          case 'BBS':
            reason = "Big Brother/Big Sister";
            break;
        }
        if (props?.donationType?.donationDesc.length > 250 && reason=="") {
          reason = props?.donationType?.donationDesc.slice(0, 250)
        }
        miscPaymentPayload.push({
          type: "DONATION" + String(props?.donationAmount?.type),
          amount: Number(props?.donationAmount?.amount).toFixed(2),
          receiptReason: reason == "" ? props?.donationType?.donationDesc : reason
        })
      }
    }

    if (nonClubData.length) {
      let commonTransferElements = props?.paygridvalues.filter(
        (x) =>
          x.store_number == String(window.sessionStorage.getItem('storeNumber'))
      );
      console.log('commonTransferElements', commonTransferElements);

      if (props?.parentAgreementIdMapper && props?.isExchangeAgreementIndicator) {
        commonTransferElements?.forEach((element) => {
          console.log(commonTransferElements, 'commonTransferElements');
          const parentSuspenseAmount: any = commonTransferElements?.find((ele: any) => ele?.Agreementid == props?.parentAgreementIdMapper?.[element?.Agreementid]);
          console.log(parentSuspenseAmount, 'Parent Suspense amount');
          if (props?.parentAgreementIdMapper[element?.Agreementid] && props?.isExchangeAgreementIndicator?.[props?.parentAgreementIdMapper?.[element?.Agreementid]] && parentSuspenseAmount?.SuspenseAmount && parentSuspenseAmount?.SuspenseAmount > 0) {
            transferSuspensePayload.push(
              {
                agreementId: String(element?.Agreementid),
                balance: Number(parentSuspenseAmount?.SuspenseAmount)?.toFixed(2)
              },
              {
                agreementId: String(props?.parentAgreementIdMapper?.[element?.Agreementid]),
                balance: '0.00'
              }
            )
          }
        })
      }

      console.log(transferSuspensePayload, 'transferSuspensePayload');

      for (let i = 0; i < commonTransferElements.length; i++) {
        if (
          Number(commonTransferElements[i].SuspenseAmount) !=
          Number(commonTransferElements[i].transferOldSuspense) &&
          commonTransferElements[i].otherIncomeType == "" &&
          commonTransferElements[i].Agreementtype != "Restitution"
        ) {
          transferSuspensePayload.push({
            balance: Number(commonTransferElements[i].SuspenseAmount).toFixed(
              2
            ),
            agreementId: String(commonTransferElements[i].Agreementid),
            isTransferToCoa: commonTransferElements[i].isCOATransfered
              ? 'Y'
              : undefined,
          });
        }
      }

      nonClubData.map((el) => {
        let isLDWActionPerformed: any = [];
        let isPolicyActionPerformed: any = [];
        console.log('actLDWArr lodfv', actLDWArr, el.Agreementid);
        if(props?.configFlags?.commonModuleFlag==1){
          if (actLDWArr.length) {
            isLDWActionPerformed = actLDWArr.filter(
              (x) => x.agreementId == el.Agreementid
            );
            if (isLDWActionPerformed.length) {
              if(isLDWActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
                isLDWActionPerformed[0].action ='A'
              }else if(isLDWActionPerformed[0].action == 2 && el.coverageStatus!='N'){
                isLDWActionPerformed[0].action ='D'
              }else{
                isLDWActionPerformed[0].action =undefined
              }
            }
          }
          if (actPolicyArr.length) {
            isPolicyActionPerformed = actPolicyArr.filter(
              (x) => x.agreementId == el.Agreementid
            );
            if (isPolicyActionPerformed.length) {
              if(isPolicyActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
                isPolicyActionPerformed[0].action ='A'
              }else if(isPolicyActionPerformed[0].action == 2 && el.coverageStatus!='N'){
                isPolicyActionPerformed[0].action ='D'
              }else{
                isPolicyActionPerformed[0].action =undefined
              }

            }
          }
        }else{
          if (actLDWArr.length) {
            isLDWActionPerformed = actLDWArr.filter(
              (x) => x.agreementId == el.Agreementid
            );
            if (isLDWActionPerformed.length) {
              isLDWActionPerformed[0].action =
                isLDWActionPerformed[0].action == 1 ? 'A' : 'D';
            }
          }
          if (actPolicyArr.length) {
            isPolicyActionPerformed = actPolicyArr.filter(
              (x) => x.agreementId == el.Agreementid
            );
            if (isPolicyActionPerformed.length) {
              isPolicyActionPerformed[0].action =
                isPolicyActionPerformed[0].action == 1 ? 'A' : 'D';
            }
          }
        }

        console.log('isLDWActionPerformed', isLDWActionPerformed);

        if (Number(el.suspenseAdd) > 0) {
          customerAccountPayload.push({
            actionType: 'C',
            accountType: 'SUSP',
            storeNumber: el.store_number,
            amount: Number(el.suspenseAdd).toFixed(2),
            agreementId: el.Agreementid,
          });
        }

        if (Number(el.suspenseSub) > 0) {
          customerAccountPayload.push({
            actionType: 'D',
            accountType: 'SUSP',
            storeNumber: el.store_number,
            amount: Number(el.suspenseSub).toFixed(2),
            agreementId: el.Agreementid,
          });
        }

        agreementPaymentPayload.push({
          agreementId: el.Agreementid,
          editedSchedule: el.editSchedule.length ? el.editSchedule : undefined,
          adjustedDueDate: el.dueDateModified && el.Nextduedate !== 'PIF' && el.Nextduedate !== 'SAC' && el.Nextduedate !== 'EPO' && el.Nextduedate && el.Nextduedate !=""
            ? moment(el.Nextduedate).format('YYYY-MM-DD')
            : undefined,
          freeTime:
            el.FreeTime == null
              ? undefined
              : {
                type: el.FreeTime.freeTimeType,
                freeDays: el.FreeTime.freeTimeDays,
                waiveOptionalService: el.FreeTime.isOptServiceWaived
                  ? 'Y'
                  : 'N',
              },
          isZeroPayment: el.isZeroPayment == true || el?.isReturnAgrFlag == 1 ? 'Y' : undefined,
          activatePolicy: isPolicyActionPerformed.length
            ? isPolicyActionPerformed[0].action
            : undefined,
          activateLdw: isLDWActionPerformed.length
            ? isLDWActionPerformed[0].action
            : undefined,
          epoPayment: el.acceptEpoFlag == 1 ||(el.dueDateModified && (el.Nextduedate == 'SAC' || el.Nextduedate == 'EPO' ))|| el.isFinalTransferSusUseApplied && el.Agreementtype != "PIF Rent" ? 'Y': undefined,
          carryLateFeeAmount:
            Number(el.carriedLateFee) > 0
              ? Number(el.carriedLateFee).toFixed(2)
              : undefined,
          waiveLateFeeAmount:
            Number(el.waivedLateFee) > 0
              ? Number(el.waivedLateFee).toFixed(2)
              : undefined,
          epoDiscount:
            el.acceptEpoFlag == 1
              ? {
                epoDiscount:el.isAcceptEPODiscounts?.epoDiscount,
                pastDueDiscount:el.isAcceptEPODiscounts?.pastDueDiscount,
                noteDiscount:el.isAcceptEPODiscounts?.noteDiscount
              }
              : undefined,
        });
      });
    }

    if(props?.securityDepositData?.length>0)
    {
      for(let i=0;i<props?.securityDepositData?.length;i++)
      {
        let amountType=props?.securityDepositData[i]?.RSDLabel?.split("-")[1]
        if(amountType==' Collect')
        {
          miscPaymentPayload.push({
            type:"SECDEP",
            amount:String(props?.securityDepositData[i]?.RSDAmount),
            agreementId:String(props?.securityDepositData[i]?.securityDepositAgreement)})
        }
      }
    }

    if (miscellaneousData.length) {
      miscellaneousData.map((el) => {
        let misItemData: any;
        let otherFee: any = [];
        let type: any;
        let receiptText: any;
        let inventoryId: any;
        let amount: any = '0.00';
        let tax: any = '0.00';
        let receiptReason:any="";
        let agreementId:any="";
        let receiptTransactionID: any;
        let customerAccountID: any;
        // let deliveryFee:any=[];
        if (el.Agreementtype.includes('Cell phone')) {
          type = 'WIREACT';
          tax = Number(el.Taxamount).toFixed(2);
          amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
          misItemData = {
            terminalId: el.miscellaneousItemInfo.terminalId,

            sessionId: el.miscellaneousItemInfo.sessionId,

            actionId: el.miscellaneousItemInfo.actionId,

            saleId: el.miscellaneousItemInfo.saleId,

            requestId: el.miscellaneousItemInfo.requestId,

            sequenceNumber: el.miscellaneousItemInfo.apiCalc,

            txnId: el.miscellaneousItemInfo.txnId,

            productId: el.miscellaneousItemInfo.productId,

            amount: el.miscellaneousItemInfo.amount,

            shopId: el.miscellaneousItemInfo.shopId,

            cashier: el.miscellaneousItemInfo.cashier,

            consumerDiscount:
              el.miscellaneousItemInfo.consumerDiscount == undefined
                ? '0.00'
                : el.miscellaneousItemInfo.consumerDiscount,

            serviceFee:
              el.miscellaneousItemInfo.serviceFee == undefined
                ? '0.00'
                : el.miscellaneousItemInfo.serviceFee,

            productName: String(el.miscellaneousItemInfo.type),

            saleAmount: String(el.miscellaneousItemInfo.salesPrice),

            mobileNumber: String(el.miscellaneousItemInfo.phoneNumber),

            carrier: String(el.miscellaneousItemInfo.carrierName),

            pricePlan: String(el.miscellaneousItemInfo.description),

            totalSale: String(el.miscellaneousItemInfo.totalSales),

            simNumber: String(el.miscellaneousItemInfo.simNumber),

            customData: el.miscellaneousItemInfo.data,
          };
          if (Number(el.miscellaneousItemInfo.nineOneOneFee) > 0) {
            otherFee.push({
              type:'CELL911',
              amount:Number(el.miscellaneousItemInfo.nineOneOneFee).toFixed(2)
            })
            amount = (Number(amount) - Number(el.miscellaneousItemInfo.nineOneOneFee)).toFixed(2)
          }

        }
        if(el.otherIncomeType=='Rental Item Sale'){
          type='CASHSALE'
          amount= (Number(el.Amount)-Number(el.Taxamount)-Number(el.deliveryAmount)).toFixed(2)
          inventoryId=String(el.Agreementid)
          tax=(Number(el.Taxamount)-Number(el.risDeliveryfeeTax)).toFixed(2)
          if(Number(el?.deliveryAmount)>0){
            otherFee.push({
              type:'DELIVFEE',
              tax:Number(el.risDeliveryfeeTax)>0 ? Number(el.risDeliveryfeeTax).toFixed(2):undefined,
              amount:Number(el.deliveryAmount).toFixed(2)
            })
          }
        }
        if (el.otherIncomeType == 'NSF Check') {
          type = 'NSF';
          amount = (
            Number(el.Amount) -
            Number(el.Taxamount) -
            Number(el.nsfFee)
          ).toFixed(2);

          receiptTransactionID = String(el.nsfData.receiptTransactionId);
          customerAccountID = String(el.nsfData.customerAccountId);
          otherFee.push({
            amount: Number(el.nsfFee).toFixed(2),
            type: 'NSFFEE',
            tax : Number(el.Taxamount).toFixed(2)
          });
        }
        if (el.otherIncomeType == 'Credit Card Charge Back') {
          type = 'CCCB';
          amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
          tax = Number(el.Taxamount).toFixed(2);
          receiptTransactionID = String(el.cccbData.receiptTransactionId);
          customerAccountID = String(el.cccbData.customerAccountId);
        }
        if (el.otherIncomeType == 'Back Rent') {
          type = 'BR';
          amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
          tax = Number(el.Taxamount).toFixed(2);
        }
        if(el.otherIncomeType=='Charge-off'){
          receiptReason=el.ReasonRefcode
          type='CO'
          amount=(Number(el.Amount)-Number(el.Taxamount)).toFixed(2)
          tax=Number(el.Taxamount).toFixed(2)
          agreementId=String(el.Agreementid)
        }
        if(el.otherIncomeType=='Xbox Gift Card') {
          type = 'XBGFTCRD'
          amount = (Number(el.Amount)- Number(el.Taxamount)).toFixed(2)
          tax = Number(el.Taxamount).toFixed(2)
          receiptReason = el.Agreementdesc
        }
        if(el.otherIncomeType=='Xbox Gift Membership') {
          type = 'XBMEMSHIP'
          amount = (Number(el.Amount)- Number(el.Taxamount)).toFixed(2)
          tax = Number(el.Taxamount).toFixed(2)
          receiptReason = el.Agreementdesc
        }
        if(el.otherIncomeType=='Non Inventory Sale') {
          type = el.Agreementdesc.includes('Acima Recovery Settlement') ? 'ACIMARECOVERY' : 'NONINVCASH'
          amount = (Number(el.Amount) - Number(el.Taxamount) - Number(el.deliveryAmount)).toFixed(2)
          tax = (Number(el.Taxamount) - Number(el.deliveryAmountTax)).toFixed(2)
          receiptReason= el.Agreementdesc
          otherFee.push({
            type:'DELIVFEE',
            amount:Number(el.deliveryAmount).toFixed(2),
            tax:Number(el.deliveryAmountTax).toFixed(2)
          })
        }
        miscPaymentPayload.push({
          type: type,
          amount: amount,
          tax: tax,
          receiptReason:receiptReason==""?undefined:receiptReason,
          agreementId:agreementId==""?undefined:agreementId,
          receiptTransactionId: receiptTransactionID,
          customerAccountId: customerAccountID,
          inventoryId: inventoryId,
          otherFee: otherFee?.length == 0 ? undefined : otherFee,
          itemData: misItemData,
          receiptText: receiptText
        });
      });
    }
    // Payload Build
    entireAcceptPayAPIArray.push({
      userTraceData:props.userTracePayload,
      raftApiFlag: String(props.configFlags.raftApiFlag),
      paymentInfo: [
        {
          globalCustomerId:props?.customerGCID==""?undefined:props?.customerGCID,
          FirstName: getPaymentInfoResp.paymentInfo.customerInfo[0].firstName,
          LastName: getPaymentInfoResp.paymentInfo.customerInfo[0].lastName,
          storeMerchantId: props.storeMerchantId || null,
          placeOrderDetails: processOrderData,
          coWorkerId: String(empId),
          storeNumber: String(window.sessionStorage.getItem('storeNumber')),
          customerId: String(customer),
          paymentOrigin: props.PayOrigin == 'Store' ? 'S' : 'H',
          exceededFTAmount: props?.exceededFTAmount > 0 ? parseFloat(props?.exceededFTAmount?.toFixed(2)) : null,
          clubPayment: clubPayload.length && !(props.clubActivate == 0) ? clubPayload : undefined,
          agreementPayment: agreementPaymentPayload.length
            ? agreementPaymentPayload
            : undefined,
          transferSuspense: transferSuspensePayload.length
            ? transferSuspensePayload
            : undefined,
          miscPayment: miscPaymentPayload.length
            ? miscPaymentPayload
            : undefined,
          paymentMethod: {
            tenderDetails: tenderDetailsPayload.length
              ? tenderDetailsPayload
              : undefined,
            cardDetails: cardDetailsPayload.length
              ? cardDetailsPayload
              : undefined,
            customerAccount: customerAccountPayload.length
              ? customerAccountPayload
              : undefined,
          },
          deactivateClub:
            props.clubActivate == 0 ? [String(customer)] : undefined,
        },
      ],
    });
    
    try {
      const resultOfDoct: any = await ProcessPaymentAPI(
        entireAcceptPayAPIArray[0]
      );
      setProcessPaymentInfo(resultOfDoct);
      if (resultOfDoct.status == 200) {
    
        const StoreExceptionRequest = updatedPayload

        if (resultOfDoct?.data && StoreExceptionRequest) {
          const payload = StoreExceptionRequest.map((obj) => {
            obj.identifierId = Number(resultOfDoct?.data?.receiptInfo[0]?.receiptId)
          })

          let epoExtensionPayload: any
          let freeDaysExtension: any
          let finalPayload: any=[]
          epoExtensionPayload = StoreExceptionRequest.filter((obj) => obj?.exceptionTypeRefCode != 'REX')
          freeDaysExtension = StoreExceptionRequest.filter((obj) => obj?.exceptionTypeRefCode == 'REX')

          if (StoreExceptionRequest?.some((obj) => obj?.exceptionTypeRefCode == 'REX')) {
            rentalExtensionAgr.map((obj) => {
              finalPayload.push({
                "identifierId": Number(resultOfDoct?.data?.receiptInfo[0]?.receiptId),
                "identifierType": 'RECEIPT',
                "storeNumber": freeDaysExtension[0]?.storeNumber,
                "exceptionTypeRefCode": "REX",
                "exceptionText": freeDaysExtension[0]?.exceptionText,
                "customerId": Number(customerId),
                "agreementId": Number(obj.Agreementid)
              })
            })
          }
          finalPayload = [...finalPayload, ...epoExtensionPayload]
          if(props?.exceededFTAmount && Number(props?.exceededFTAmount) > 0) {

          let finalPayloadCopy: any = [...finalPayload];
          const freeTimeExceptions = finalPayloadCopy.filter((e: any) => e.exceptionTypeRefCode === 'REX');

          freeTimeExceptions.forEach((element: any) => {
            let modifiedFTObject = {
              ...element,
              exceptionTypeRefCode: 'CFT',
              approverId: approverId
            };
            
            finalPayload.push(modifiedFTObject);
          });
        }

          const Request = {
            storeException: finalPayload
          }
          const StoreExceptionRes = storeException(Request)
          setStoreExceptionPayload([])
        }
        settxtChange(true);
        settran(true);
        setReceiptID(resultOfDoct.data.receiptInfo[0].receiptId);
        const getUrl = window.location.href;
        if (getUrl.includes('/customerorder/')) {
          invokeCOReceipt(resultOfDoct);
        } else {
          invokeReceiptSameStore(resultOfDoct);
        }
        if (retentionType && agreementPaymentPayload.length) {
          const filterAppliedFreeTime = nonClubData.filter(
            (el) =>
              el.FreeTime !== null &&
              el.AgreementNumber ==
              location?.state?.retentionData?.agreementNumber
          );
          const filterAppliedEPO = nonClubData.filter(
            (el) =>
              el.acceptEpoFlag !== 0 &&
              el.AgreementNumber ==
              location?.state?.retentionData?.agreementNumber
          );
          const today = new Date();
          const date = today.toISOString().split('T')[0];
          if (filterAppliedFreeTime.length) {
            const freeTimeAgrActivityPayload = {
              agreementActivities: [
                {
                  activityDate: date,
                  agreementId: filterAppliedFreeTime[0].Agreementid,
                  activityDescription: 'Agr Retention Extension',
                  agreementActivityType: 'AGMRETEXT',
                },
              ],
            };
            await postAgreementActivity(freeTimeAgrActivityPayload);
          } else if (filterAppliedEPO.length) {
            const EPOAgrActivityPayload = {
              agreementActivities: [
                {
                  activityDate: date,
                  agreementId: filterAppliedEPO[0].Agreementid,
                  activityDescription: 'Agr Retention EPO',
                  agreementActivityType: 'AGMEPORET',
                },
              ],
            };
            await postAgreementActivity(EPOAgrActivityPayload);
          }
        }
        // if (entireAcceptPayAPIArray[0].paymentInfo[0].placeOrderDetails) {
        //   const payload: any = {
        //     placeOrderDetails:
        //       entireAcceptPayAPIArray[0].paymentInfo[0].placeOrderDetails,
        //   };
        //   const placeOrderResponse: any = await PlaceOrderCO(payload);
        //   console.log(
        //     'placeOrderResponse in Process payment',
        //     placeOrderResponse
        //   );
        // }
        if (props?.exchangeAgreementNumber) {
          unEnrollAutoPay();
        }
      } else {
        setload(false);
        const storeErrorMessage = resultOfDoct?.data?.errors[0]?.error?.message;
        if(storeErrorMessage && storeErrorMessage.includes('Amount mismatch')) {
          setIsAmountMismatch(true);
        } else {
          setTranFail(true);
        }

        if (
          resultOfDoct.status === 400 &&
          resultOfDoct?.data?.errors[0]?.error?.serviceResponse?.Body
            ?.StatusInfo?.StatusCodeDetail
        ) {

          let declineCode=resultOfDoct?.data?.errors[0]?.error?.serviceResponse?.Body
            ?.declineCode
          if(resultOfDoct?.data?.errors[0]?.error?.serviceResponse?.Body
            ?.StatusInfo?.statusCode){
            let internalStatusCode =resultOfDoct?.data?.errors[0]?.error?.serviceResponse?.Body
              ?.StatusInfo?.statusCode
            switch(internalStatusCode){
              case 'DP':
                declineCode = 712;
                break;
            }

          }
          let cardDeclineMessage ="Transaction Declined. Please try a different card."
          switch(Number(declineCode)){
            case 39:
              cardDeclineMessage="Transaction Declined. Insufficient Fund. Please try a different card";
              break;
            case 127:
              cardDeclineMessage="Transaction Declined. Exceeds Approval Amount Limit";
              break;
            case 327:
              cardDeclineMessage="Transaction Declined. Cardholder transaction not permitted";
              break;
            case 322:
              cardDeclineMessage="Transaction Declined. Invalid Transaction";
              break;
            case 330:
              cardDeclineMessage="Transaction Declined. Invalid Payment Type";
              break;
            case 321:
              cardDeclineMessage="Transaction Declined. Invalid Merchant.";
              break;
            case 217:
              cardDeclineMessage="Transaction Declined. Card Already Active.";
              break;
            case 822:
              cardDeclineMessage="Transaction Declined. Card token not found.";
              break;
            case 340:
              cardDeclineMessage="Transaction Declined. Invalid Amount.";
              break;
            case 11:
              cardDeclineMessage="Transaction Declined. Transaction not permitted.";
              break;
            case 110:
              cardDeclineMessage="Transaction Declined. Insufficient Fund. Please try a different card";
              break;
            case 301:
              cardDeclineMessage="Transaction Declined. Invalid Account Number. Please try with different card.";
              break;
            case 326:
              cardDeclineMessage="Transaction Declined exceeded number of Pin entries. Please try with different card.";
              break;
            case 324:
              cardDeclineMessage="Transaction Declined. Invalid Pin. Please try with valid Pin Number.";
              break;
            case 9:
              cardDeclineMessage="Transaction Declined. Invalid Card Number. Please try with different card.";
              break;
            case 4:
              cardDeclineMessage="Transaction Declined. Expired Card. Please try with different card.";
              break;
            case 13:
              cardDeclineMessage="Transaction Declined. Please try a different card.";
              break;
            case 26:
              cardDeclineMessage="Transaction Declined. Exceeds withdrawal limit please try a different card";
              break;
          }
          setCardPaymentFailedMessage(cardDeclineMessage);
          setTranFail(true);
        }
      }
    } catch {
      setTranFail(true);
      setload(false);
    }
  };

  const invokeAcceptPayAPIMultiStore = async (updatedPayload?: any) => {
    let cardData: any = [];
    props?.setpaymentMadeOptimized(true);
    cardData = getcardResponse.CardInfo.filter(
      (e) => Number(e.Amount) > 0 && e.cvvEncrypt.length >= 3
    );

    if (chargeCardArray.length > 0) {
      cardData = [...cardData, ...chargeCardArray];
    }

    let processOrderData: any = undefined;
    if (
      props.isCoPrelim &&
      (props.provideEAFlag == null || props.provideEAFlag == 'No')
    ) {
      processOrderData = {
        eaFlag: props.provideEAFlag,
        agreementNumber: props.provideAgrNumberPO,
      };
    } else if (props.isCoPrelim && props.provideEAFlag == 'Yes') {
      const updatedPlaceOrderAddressData = props.providePOAddressData;
      // updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
      processOrderData = {
        eaFlag: props.provideEAFlag,
        agreementNumber: props.provideAgrNumberPO,
        shippingAddress: updatedPlaceOrderAddressData,
        items: props.providePlaceOrderItemData
      };
    }

    const groupByStoreNumber = props.selectedAgreements.reduce(
      (group, product) => {
        const { store_number } = product;
        group[store_number] = group[store_number] ?? [];
        group[store_number].push(product);
        return group;
      },
      {}
    );

    const entireAcceptPayAPIArray: any = [];

    const keys = Object.keys(groupByStoreNumber);
    const logInStoreCheck = keys?.includes(String(window.sessionStorage.getItem("storeNumber")))

    if((!logInStoreCheck&&props?.donationAmount?.respFlag && props?.donationAmount?.amount != "0.00"&&props?.donationBtnFlag)||
      (!logInStoreCheck &&props.clubActivate == 0 )
    ){
      const cardDetailsPayload: any = [];
      const miscPaymentPayload:any=[];

      let donationAmount= props?.donationAmount?.amount
      //Conven Fee for Origin Store
      if(props.PayOrigin != 'Store'&&!logInStoreCheck){
        donationAmount=donationAmount+(Number(props?.convAmtWTax)>0?Number(props?.convAmtWTax):0)
      }
      for (let i = 0; i < cardData.length; i++) {
        const cardtoken: any =
          cardToken.length == 0
            ? ''
            : cardToken.filter(
              (e) => String(cardData[i].lastFour) == String(e.lastFour)
            );
        if (Number(donationAmount) < Number(cardData[i].Amount)) {
          cardDetailsPayload.push({
            amount: Number(donationAmount).toFixed(2),
            authNumber: '',
            cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
            cardLastFour: cardData[i].lastFour,
            cardType: cardData[i].cardRef,
            cardTransactionType:
              cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
            externalTransactionId: '',
            expirationDate: cardData[i].expirationDate,
            paymentId: cardData[i].paymentId,
            paymentToken:cardData[i].cardToken,
            billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
          });
          cardData[i].Amount = (
            Number(cardData[i].Amount) - Number(donationAmount)
          ).toFixed(2);
          break;
        } else if (Number(donationAmount) > Number(cardData[i].Amount)) {
          cardDetailsPayload.push({
            amount: Number(cardData[i].Amount).toFixed(2),
            authNumber: '',
            cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
            cardLastFour: cardData[i].lastFour,
            cardType: cardData[i].cardRef,
            cardTransactionType:
              cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
            externalTransactionId: '',
            paymentId: cardData[i].paymentId,
            expirationDate: cardData[i].expirationDate,
            paymentToken:cardData[i].cardToken,
            billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
          });
          donationAmount =
            Number(donationAmount) - Number(cardData[i].Amount);
          cardData.splice(i, 1);
          i = i - 1;
        } else {
          cardDetailsPayload.push({
            amount: Number(cardData[i].Amount).toFixed(2),
            authNumber: '',
            cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
            cardLastFour: cardData[i].lastFour,
            cardType: cardData[i].cardRef,
            cardTransactionType:
              cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
            externalTransactionId: '',
            paymentId: cardData[i].paymentId,
            expirationDate: cardData[i].expirationDate,
            paymentToken:cardData[i].cardToken,
            billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
          });
          cardData.splice(i, 1);
          i = i - 1;
        }
      }

      if (props.donationAmount != undefined) {
        if (props?.donationAmount?.respFlag && props?.donationAmount?.amount != "0.00"&&props?.donationBtnFlag) {
          let reason: any = ""
          switch (props?.donationAmount?.type) {
            case 'BGC':
              reason = "Boys and Girls Club";
              break;
            case 'STON':
              reason = "Fill The Fridge";
              break;
            case 'BBS':
              reason = "Big Brother/Big Sister";
              break;
          }
          if (props?.donationType?.donationDesc.length > 250 && reason=="") {
            reason = props?.donationType?.donationDesc.slice(0, 250)
          }
          miscPaymentPayload.push({
            type: "DONATION" + String(props?.donationAmount?.type),
            amount: Number(props?.donationAmount?.amount).toFixed(2),
            receiptReason: reason == "" ? props?.donationType?.donationDesc : reason
          })
        }
      }

      if(props.securityDepositData.length>0)
    {
        for(let i=0;i<props.securityDepositData?.length;i++)
      {
          let amountType=props.securityDepositData[i]?.RSDLabel.split("-")[1]
          if(amountType==' Collect')
        {
            miscPaymentPayload.push({
              type:"SECDEP",
              amount:String(props.securityDepositData[i]?.RSDAmount),
              agreementId:String(props.securityDepositData[i]?.securityDepositAgreement)})
          }

        }
      }

      entireAcceptPayAPIArray.push({
        raftApiFlag: String(props.configFlags.raftApiFlag),
        paymentInfo: [
          {
            globalCustomerId: props?.customerGCID == "" ? undefined : props?.customerGCID,
            FirstName: getPaymentInfoResp.paymentInfo.customerInfo[0].firstName,
            LastName: getPaymentInfoResp.paymentInfo.customerInfo[0].lastName,
            storeMerchantId: props.storeMerchantId || null,
            placeOrderDetails: processOrderData,
            coWorkerId: String(empId),
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            customerId: String(customer),
            paymentOrigin: props.PayOrigin == 'Store'?"S":"H",
            clubPayment: undefined,
            agreementPayment: undefined,
            transferSuspense: undefined,
            miscPayment: miscPaymentPayload.length
              ? miscPaymentPayload
              : undefined,
            paymentMethod: {
              tenderDetails:undefined,
              cardDetails: cardDetailsPayload.length
                ? cardDetailsPayload
                : undefined,
              customerAccount: undefined,
            },
            deactivateClub:
              props.clubActivate == 0 ? [String(customer)] : undefined,
          },
        ],
      });

    }

    keys.forEach((key) => {
      console.log('keylogger', key);
      console.log('individualkeyval', groupByStoreNumber[key]);
      collectStoreNumber.push(groupByStoreNumber[key][0].store_number);
      let creditOnAccount: any = [];
      let IPSuspense: any = [];
      let webleadAmount: any = [];
      let COAAmount = 0;
      let overPaymentSuspenseAmount = 0;
      let changeNum = Number(Number(Changes).toFixed(2));
      let clubData: any = [];
      let nonClubData: any = [];
      let miscellaneousData: any = [];
      const clubPayload: any = [];
      const transferSuspensePayload: any = [];
      const agreementPaymentPayload: any = [];
      const miscPaymentPayload: any = [];
      const tenderDetailsPayload: any = [];
      const cardDetailsPayload: any = [];
      const customerAccountPayload: any = [];
      const changeAmountPayload: any =
        radioFlags == '0'
          ? Number(Number(fixedChange(Changes)).toFixed(2))
          : 0.0;

      let merchentIdData =groupByStoreNumber[key]

      console.log("merchentIdData",merchentIdData)

      clubData = groupByStoreNumber[key].filter(
        (el) => el.Agreementtype === 'Club'
      );
      nonClubData = groupByStoreNumber[key].filter(
        (el) =>
          el.Agreementtype !== 'Club' &&
          !el.Agreementtype.includes('Cell phone') &&
          !el.Agreementtype.includes('Restitution') &&
          el.otherIncomeType != 'Rental Item Sale' &&
          el.otherIncomeType != 'NSF Check' &&
          el.otherIncomeType != 'Credit Card Charge Back' &&
          el.otherIncomeType != 'Back Rent' &&
          el.otherIncomeType != 'Charge-off' &&
          el.otherIncomeType != 'Xbox Gift Card' &&
          el.otherIncomeType != 'Xbox Gift Membership' &&
          el.otherIncomeType != 'Non Inventory Sale'
      );
      miscellaneousData = groupByStoreNumber[key].filter(
        (el) =>
          el.Agreementtype.includes('Cell phone') ||
          el.Agreementtype.includes('Restitution') ||
          el.otherIncomeType == 'Rental Item Sale' ||
          el.otherIncomeType == 'NSF Check' ||
          el.otherIncomeType == 'Credit Card Charge Back' ||
          el.otherIncomeType == 'Back Rent' ||
          el.otherIncomeType == 'Charge-off' ||
          el.otherIncomeType == 'Xbox Gift Card' ||
          el.otherIncomeType == 'Xbox Gift Membership' ||
          el.otherIncomeType == 'Non Inventory Sale'
      );
      console.log('clubData logger', clubData);
      console.log('nonClubData logger', nonClubData);
      console.log('miscellaneousData logger', miscellaneousData);

      if (props.availableCredsremaining.length > 0) {
        webleadAmount = props.availableCredsremaining.filter(
          (x) => x.balanceType === 'WEBLEAD'
        );
        creditOnAccount = props.availableCredsremaining.filter(
          (x) => x.balanceType === 'COA'
        );
        IPSuspense = props.availableCredsremaining.filter(
          (x) => x.balanceType === 'IP Suspence'
        );
      }

      if (
        radioFlags == '1' &&
        Number(Changes) > 0 &&
        props.suspensehide == false &&
        props.CreditRadio == true
      ) {
        COAAmount = radioFlags == '1' && changeNum >= 0 ? changeNum : 0;
      } else if (
        radioFlags == '1' &&
        Number(Changes) > 0 &&
        props.suspensehide == true
      ) {
        overPaymentSuspenseAmount =
          radioFlags == '1' && changeNum >= 0 ? changeNum : 0;
      }

      if (
        creditOnAccount.length > 0 &&
        Number(creditOnAccount[0].accountBalance) > 0
      ) {
        customerAccountPayload.push({
          actionType: 'D',
          accountType: 'COA',
          storeNumber: String(window.sessionStorage.getItem('storeNumber')),
          amount: Number(creditOnAccount[0].accountBalance).toFixed(2),
        });
      }

      if (
        webleadAmount.length > 0 &&
        1 * Number(webleadAmount[0].accountBalance) > 0
      ) {
        customerAccountPayload.push({
          actionType: 'D',
          accountType: 'WEBLEAD',
          storeNumber: String(window.sessionStorage.getItem('storeNumber')),
          amount: (1 * Number(webleadAmount[0].accountBalance)).toFixed(2),
        });
      }

      if (IPSuspense.length > 0 && 1 * Number(IPSuspense[0].accountBalance) > 0) {
        customerAccountPayload.push({
          actionType: "D",
          accountType: "SIPS",
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
          amount: (1 * Number(IPSuspense[0].accountBalance)).toFixed(2),
        });
      }

      if (Number(COAAmount) > 0) {
        customerAccountPayload.push({
          actionType: 'C',
          accountType: 'COA',
          storeNumber: String(window.sessionStorage.getItem('storeNumber')),
          amount: Number(COAAmount).toFixed(2),
        });
      }


      if (Number(overPaymentSuspenseAmount) > 0) {
        customerAccountPayload.push({
          actionType: 'C',
          accountType: 'SUSP',
          storeNumber: String(window.sessionStorage.getItem('storeNumber')),
          amount: Number(overPaymentSuspenseAmount).toFixed(2),
        });
      }

      if (Number(carryAmount) > 0) {
        customerAccountPayload.push({
          actionType: 'C',
          accountType: 'CR',
          storeNumber: String(window.sessionStorage.getItem('storeNumber')),
          amount: Number(carryAmount).toFixed(2),
        });
      }

      if (cardData.length) {
        let agreementAmountDue = 0;
        const filteredDataForCard = groupByStoreNumber[key].filter(
          (el) => el.store_number == key
        );
        console.log('filteredDataForCard', filteredDataForCard);

        filteredDataForCard.map((el) => {
          const individualAgrTotal =
            Number(el.Amount) +
            Number(el.suspenseAdd) -
            Number(el.suspenseSub);
          agreementAmountDue =
            agreementAmountDue +
            (individualAgrTotal > 0 ? individualAgrTotal : 0);
        });
        //Conven Fee for Origin Store
        if(props.PayOrigin != 'Store'&&key==String(window.sessionStorage.getItem("storeNumber"))){
          agreementAmountDue=agreementAmountDue+(Number(props?.convAmtWTax)>0?Number(props?.convAmtWTax):0)
        }
        //Donation for Origin Store
        if(key==String(window.sessionStorage.getItem("storeNumber"))&&props?.donationAmount?.respFlag && props?.donationAmount?.amount != "0.00"&&props?.donationBtnFlag){
          agreementAmountDue=agreementAmountDue+(Number(props?.donationAmount?.amount )>0?Number(props?.donationAmount?.amount):0)
        }
        console.log('agreementAmountDuesds', agreementAmountDue);

        for (let i = 0; i < cardData.length; i++) {
          const cardtoken: any =
            cardToken.length == 0
              ? ''
              : cardToken.filter(
                (e) => String(cardData[i].lastFour) == String(e.lastFour)
              );
          if (Number(agreementAmountDue) < Number(cardData[i].Amount)) {
            cardDetailsPayload.push({
              amount: Number(agreementAmountDue).toFixed(2),
              authNumber: '',
              cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
              cardLastFour: cardData[i].lastFour,
              cardType: cardData[i].cardRef,
              cardTransactionType:
                cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
              externalTransactionId: '',
              expirationDate: cardData[i].expirationDate,
              paymentId: cardData[i].paymentId,
              paymentToken:cardData[i].cardToken,
              billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
            });
            cardData[i].Amount = (
              Number(cardData[i].Amount) - Number(agreementAmountDue)
            ).toFixed(2);
            break;
          } else if (Number(agreementAmountDue) > Number(cardData[i].Amount)) {
            cardDetailsPayload.push({
              amount: Number(cardData[i].Amount).toFixed(2),
              authNumber: '',
              cardLastFour: cardData[i].lastFour,
              cardType: cardData[i].cardRef,
              cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
              cardTransactionType:
                cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
              externalTransactionId: '',
              paymentId: cardData[i].paymentId,
              expirationDate: cardData[i].expirationDate,
              paymentToken:cardData[i].cardToken,
              billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
            });
            agreementAmountDue =
              Number(agreementAmountDue) - Number(cardData[i].Amount);
            cardData.splice(i, 1);
            i = i - 1;
          } else {
            cardDetailsPayload.push({
              amount: Number(cardData[i].Amount).toFixed(2),
              authNumber: '',
              cvv: cardData[i]?.cvv != ""? cardData[i]?.cvv : undefined,
              cardLastFour: cardData[i].lastFour,
              cardType: cardData[i].cardRef,
              cardTransactionType:
                cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
              externalTransactionId: '',
              paymentId: cardData[i].paymentId,
              expirationDate: cardData[i].expirationDate,
              paymentToken:cardData[i].cardToken,
              billingAddress: props.billingAddressValidation(cardData[i].billingAddress),
            });
            cardData.splice(i, 1);
            i = i - 1;
          }
        }
      }
      let cashArray = props?.tendertypeglobalarray?.filter(x=>x?.tendertype=='Cash')
      console.log('cardDetailsPayload logger', cashArray);
      if (Number(cashArray[0]?.Amount) > 0) {
        tenderDetailsPayload.push({
          type: 'CASH',
          amount: Number(cashArray[0]?.Amount).toFixed(2),
        });
      }
      if (checkDetail.length) {
        checkDetail.map((el) => {
          if (el.Type_info == 'Check') {
            tenderDetailsPayload.push({
              type: 'CHK',
              amount: el.Amount_rac,
              tenderNumber: el.check_no,
              checkType: el.check_type,
              checkWrittenBy: el.Written_by,
              checkWrittenTo: el.Written_too,
            });
          } else if (el.Type_info == 'Money Order') {
            tenderDetailsPayload.push({
              type: 'MO',
              amount: el.Amount_rac,
              tenderNumber: el.check_no,
              purchaser: el.Written_by,
              issuer: el.Written_too,
            });
          }
        });
        console.log('checkDetail data from array', checkDetail);
      }
      if (Number(changeAmountPayload) > 0) {
        tenderDetailsPayload.push({
          type: 'CHG',
          amount: Number(changeAmountPayload).toFixed(2),
        });
      }
      if (clubData.length) {
        clubData.map((el) => {
          clubPayload.push({
            customerClubId: String(el.customerClubId),
            isZeroPayment: el.isZeroPayment == true || el?.isReturnAgrFlag == 1 ? 'Y' : undefined,
            editedSchedule: el.editSchedule.length
              ? el.editSchedule
              : undefined,
            adjustedDueDate: el.dueDateModified
              ? moment(el.Nextduedate).format('YYYY-MM-DD')
              : undefined,
          });
        });
      }

      if (props.donationAmount != undefined&&key==String(window.sessionStorage.getItem("storeNumber"))) {
        if (props?.donationAmount?.respFlag && props?.donationAmount?.amount != "0.00"&&props?.donationBtnFlag) {
          let reason: any = ""
          switch (props?.donationAmount?.type) {
            case 'BGC':
              reason = "Boys and Girls Club";
              break;
            case 'STON':
              reason = "Fill The Fridge";
              break;
            case 'BBS':
              reason = "Big Brother/Big Sister";
              break;
          }
          if (props?.donationType?.donationDesc.length > 250 && reason=="") {
            reason = props?.donationType?.donationDesc.slice(0, 250)
          }
          miscPaymentPayload.push({
            type: "DONATION" + String(props?.donationAmount?.type),
            amount: Number(props?.donationAmount?.amount).toFixed(2),
            receiptReason: reason == "" ? props?.donationType?.donationDesc : reason
          })
        }
      }

      if(props.securityDepositData.length>0)
      {
        for(let i=0;i<props.securityDepositData?.length;i++)
        {
          let amountType=props.securityDepositData[i]?.RSDLabel.split("-")[1]
          if(amountType==' Collect')
          {
            miscPaymentPayload.push({
              type:"SECDEP",
              amount:String(props.securityDepositData[i]?.RSDAmount),
              agreementId:String(props.securityDepositData[i]?.securityDepositAgreement)})
          }

        }
      }

      if (nonClubData.length) {
        let commonTransferElements = props?.paygridvalues.filter(
          (x) => x.store_number == key
        );
        console.log('commonTransferElements', commonTransferElements);

        if (props?.parentAgreementIdMapper && props?.isExchangeAgreementIndicator) {
          commonTransferElements?.forEach((element) => {
            console.log(commonTransferElements, 'commonTransferElements');
            const parentSuspenseAmount: any = commonTransferElements?.find((ele: any) => ele?.Agreementid == props?.parentAgreementIdMapper?.[element?.Agreementid]);
            console.log(parentSuspenseAmount, 'Parent Suspense amount');
            if (props?.parentAgreementIdMapper[element?.Agreementid] && props?.isExchangeAgreementIndicator?.[props?.parentAgreementIdMapper?.[element?.Agreementid]] && parentSuspenseAmount?.SuspenseAmount && parentSuspenseAmount?.SuspenseAmount > 0) {
              transferSuspensePayload.push(
                {
                  agreementId: String(element?.Agreementid),
                  balance: Number(parentSuspenseAmount?.SuspenseAmount)?.toFixed(2)
                },
                {
                  agreementId: String(props?.parentAgreementIdMapper?.[element?.Agreementid]),
                  balance: '0.00'
                }
              )
            }
          })
        }

        for (let i = 0; i < commonTransferElements.length; i++) {
          if (
            Number(commonTransferElements[i].SuspenseAmount) !=
            Number(commonTransferElements[i].transferOldSuspense) &&
            commonTransferElements[i].otherIncomeType == "" &&
            commonTransferElements[i].Agreementtype != "Restitution"
          ) {
            transferSuspensePayload.push({
              balance: Number(commonTransferElements[i].SuspenseAmount).toFixed(
                2
              ),
              agreementId: String(commonTransferElements[i].Agreementid),
              isTransferToCoa: commonTransferElements[i].isCOATransfered
                ? '1'
                : undefined,
            });
          }
        }

        nonClubData.map((el) => {
          let isLDWActionPerformed: any = [];
          let isPolicyActionPerformed: any = [];
          console.log('actLDWArr lodfv', actLDWArr, el.Agreementid);

          if(props?.configFlags?.commonModuleFlag==1){
            if (actLDWArr.length) {
              isLDWActionPerformed = actLDWArr.filter(
                (x) => x.agreementId == el.Agreementid
              );
              if (isLDWActionPerformed.length) {
                if(isLDWActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
                  isLDWActionPerformed[0].action ='A'
                }else if(isLDWActionPerformed[0].action == 2 && el.coverageStatus!='N'){
                  isLDWActionPerformed[0].action ='D'
                }else{
                  isLDWActionPerformed[0].action =undefined
                }
              }
            }
            if (actPolicyArr.length) {
              isPolicyActionPerformed = actPolicyArr.filter(
                (x) => x.agreementId == el.Agreementid
              );
              if (isPolicyActionPerformed.length) {
                if(isPolicyActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
                  isPolicyActionPerformed[0].action ='A'
                }else if(isPolicyActionPerformed[0].action == 2 && el.coverageStatus!='N'){
                  isPolicyActionPerformed[0].action ='D'
                }else{
                  isPolicyActionPerformed[0].action =undefined
                }

              }
            }
          }else{
            if (actLDWArr.length) {
              isLDWActionPerformed = actLDWArr.filter(
                (x) => x.agreementId == el.Agreementid
              );
              if (isLDWActionPerformed.length) {
                isLDWActionPerformed[0].action =
                  isLDWActionPerformed[0].action == 1 ? 'A' : 'D';
              }
            }
            if (actPolicyArr.length) {
              isPolicyActionPerformed = actPolicyArr.filter(
                (x) => x.agreementId == el.Agreementid
              );
              if (isPolicyActionPerformed.length) {
                isPolicyActionPerformed[0].action =
                  isPolicyActionPerformed[0].action == 1 ? 'A' : 'D';
              }
            }
          }
          console.log('isLDWActionPerformed', isLDWActionPerformed);

          if (Number(el.suspenseAdd) > 0) {
            customerAccountPayload.push({
              actionType: 'C',
              accountType: 'SUSP',
              storeNumber: el.store_number,
              amount: Number(el.suspenseAdd).toFixed(2),
              agreementId: el.Agreementid,
            });
          }

          if (Number(el.suspenseSub) > 0) {
            customerAccountPayload.push({
              actionType: 'D',
              accountType: 'SUSP',
              storeNumber: el.store_number,
              amount: Number(el.suspenseSub).toFixed(2),
              agreementId: el.Agreementid,
            });
          }

          agreementPaymentPayload.push({
            agreementId: el.Agreementid,
            editedSchedule: el.editSchedule.length
              ? el.editSchedule
              : undefined,
            adjustedDueDate: el.dueDateModified && el.Nextduedate !== 'PIF' && el.Nextduedate !== 'SAC' && el.Nextduedate !== 'EPO' && el.Nextduedate && el.Nextduedate !=""
              ? moment(el.Nextduedate).format('YYYY-MM-DD')
              : undefined,
            freeTime:
              el.FreeTime == null
                ? undefined
                : {
                  type: el.FreeTime.freeTimeType,
                  freeDays: el.FreeTime.freeTimeDays,
                  waiveOptionalService: el.FreeTime.isOptServiceWaived
                    ? 'Y'
                    : 'N',
                },
            isZeroPayment: el.isZeroPayment == true || el?.isReturnAgrFlag == 1 ? 'Y' : undefined,
            activatePolicy: isPolicyActionPerformed.length
              ? isPolicyActionPerformed[0].action
              : undefined,
            activateLdw: isLDWActionPerformed.length
              ? isLDWActionPerformed[0].action
              : undefined,
            epoPayment: el.acceptEpoFlag == 1 ||(el.dueDateModified && (el.Nextduedate == 'SAC' || el.Nextduedate == 'EPO' ))|| el.isFinalTransferSusUseApplied && el.Agreementtype != "PIF Rent" ? 'Y': undefined,
            carryLateFeeAmount:
              Number(el.carriedLateFee) > 0
                ?Number(el.carriedLateFee).toFixed(2)
                : undefined,
            waiveLateFeeAmount:
              Number(el.waivedLateFee) > 0
                ? Number(el.waivedLateFee).toFixed(2)
                : undefined,
            epoDiscount:
              el.acceptEpoFlag == 1
                ? {
                  epoDiscount:el.isAcceptEPODiscounts?.epoDiscount,
                  pastDueDiscount:el.isAcceptEPODiscounts?.pastDueDiscount,
                  noteDiscount:el.isAcceptEPODiscounts?.noteDiscount
                }
                : undefined,
          });
        });
      }

      if (miscellaneousData.length) {
        miscellaneousData.map((el) => {
          let misItemData: any;
          let otherFee: any = [];
          let type: any;
          let receiptText: any;
          let inventoryId: any;
          let amount: any = '0.00';
          let tax: any = '0.00';
          let receiptReason:any="";
          let agreementId:any="";
          let receiptTransactionID: any;
          let customerAccountID: any;
          // let deliveryFee:any=[];
          if (el.Agreementtype.includes('Cell phone')) {
            type = 'WIREACT';
            tax = Number(el.Taxamount).toFixed(2);
            amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
            misItemData = {
              terminalId: el.miscellaneousItemInfo.terminalId,

              sessionId: el.miscellaneousItemInfo.sessionId,

              actionId: el.miscellaneousItemInfo.actionId,

              saleId: el.miscellaneousItemInfo.saleId,

              requestId: el.miscellaneousItemInfo.requestId,

              sequenceNumber: el.miscellaneousItemInfo.apiCalc,

              txnId: el.miscellaneousItemInfo.txnId,

              productId: el.miscellaneousItemInfo.productId,

              amount: el.miscellaneousItemInfo.amount,

              shopId: el.miscellaneousItemInfo.shopId,

              cashier: el.miscellaneousItemInfo.cashier,

              consumerDiscount:
                el.miscellaneousItemInfo.consumerDiscount == undefined
                  ? '0.00'
                  : el.miscellaneousItemInfo.consumerDiscount,

              serviceFee:
                el.miscellaneousItemInfo.serviceFee == undefined
                  ? '0.00'
                  : el.miscellaneousItemInfo.serviceFee,

              productName: String(el.miscellaneousItemInfo.type),

              saleAmount: String(el.miscellaneousItemInfo.salesPrice),

              mobileNumber: String(el.miscellaneousItemInfo.phoneNumber),

              carrier: String(el.miscellaneousItemInfo.carrierName),

              pricePlan: String(el.miscellaneousItemInfo.description),

              totalSale: String(el.miscellaneousItemInfo.totalSales),

              simNumber: String(el.miscellaneousItemInfo.simNumber),

              customData: el.miscellaneousItemInfo.data,
            };
            if (Number(el.miscellaneousItemInfo.nineOneOneFee) > 0) {
              otherFee.push({
                type:'CELL911',
                amount:Number(el.miscellaneousItemInfo.nineOneOneFee).toFixed(2)
              })
              amount = (Number(amount) - Number(el.miscellaneousItemInfo.nineOneOneFee)).toFixed(2)
            }

          }
          if(el.otherIncomeType=='Rental Item Sale'){
            type='CASHSALE'
            amount= (Number(el.Amount)-Number(el.Taxamount)-Number(el.deliveryAmount)).toFixed(2)
            inventoryId=String(el.Agreementid)
            tax=(Number(el.Taxamount)-Number(el.risDeliveryfeeTax)).toFixed(2)
            if(Number(el?.deliveryAmount)>0){
              otherFee.push({
                type:'DELIVFEE',
                tax:Number(el.risDeliveryfeeTax)>0 ? Number(el.risDeliveryfeeTax).toFixed(2):undefined,
                amount:Number(el.deliveryAmount).toFixed(2)
              })
            }
          }
          if (el.otherIncomeType == 'NSF Check') {
            type = 'NSF';
            amount = (
              Number(el.Amount) -
              Number(el.Taxamount) -
              Number(el.nsfFee)
            ).toFixed(2);
            tax = Number(el.Taxamount).toFixed(2);
            receiptTransactionID = String(el.nsfData.receiptTransactionId);
            customerAccountID = String(el.nsfData.customerAccountId);
            otherFee.push({
              amount: Number(el.nsfFee).toFixed(2),
              type: 'NSFFEE',
            });
          }
          if (el.otherIncomeType == 'Credit Card Charge Back') {
            type = 'CCCB';
            amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
            tax = Number(el.Taxamount).toFixed(2);
            receiptTransactionID = String(el.cccbData.receiptTransactionId);
            customerAccountID = String(el.cccbData.customerAccountId);
          }
          if (el.otherIncomeType == 'Back Rent') {
            type = 'BR';
            amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
            tax = Number(el.Taxamount).toFixed(2);
          }
          if(el.otherIncomeType=='Charge-off'){
            receiptReason=el.ReasonRefcode
            type='CO'
            amount=(Number(el.Amount)-Number(el.Taxamount)).toFixed(2)
            tax=Number(el.Taxamount).toFixed(2)
            agreementId=String(el.Agreementid)
          }
          if(el.otherIncomeType=='Xbox Gift Card') {
            type = 'XBGFTCRD'
            amount = (Number(el.Amount)- Number(el.Taxamount)).toFixed(2)
            tax = Number(el.Taxamount).toFixed(2)
            receiptReason = el.Agreementdesc
          }
          if(el.otherIncomeType=='Xbox Gift Membership') {
            type = 'XBMEMSHIP'
            amount = (Number(el.Amount)- Number(el.Taxamount)).toFixed(2)
            tax = Number(el.Taxamount).toFixed(2)
            receiptReason = el.Agreementdesc
          }
          if(el.otherIncomeType=='Non Inventory Sale') {
            type = el.Agreementdesc.includes('Acima Recovery Settlement') ? 'ACIMARECOVERY' : 'NONINVCASH'
            amount = (Number(el.Amount) - Number(el.Taxamount) - Number(el.deliveryAmount)).toFixed(2)
            tax = (Number(el.Taxamount) - Number(el.deliveryAmountTax)).toFixed(2)
            receiptReason= el.Agreementdesc
            otherFee.push({
              type:'DELIVFEE',
              amount:Number(el.deliveryAmount).toFixed(2),
              tax:Number(el.deliveryAmountTax).toFixed(2)
            })
          }

          miscPaymentPayload.push({
            type: type,
            amount: amount,
            tax: tax,
            receiptReason:receiptReason==""?undefined:receiptReason,
            agreementId:agreementId==""?undefined:agreementId,
            receiptTransactionId: receiptTransactionID,
            customerAccountId: customerAccountID,
            inventoryId: inventoryId,
            otherFee: otherFee?.length == 0 ? undefined : otherFee,
            itemData: misItemData,
            receiptText: receiptText
          });
        });
      }
      // Payload Build
      entireAcceptPayAPIArray.push({
        raftApiFlag: String(props.configFlags.raftApiFlag),
        paymentInfo: [
          {
            globalCustomerId:props?.customerGCID==""?undefined:props?.customerGCID,
            FirstName: getPaymentInfoResp.paymentInfo.customerInfo[0].firstName,
            LastName: getPaymentInfoResp.paymentInfo.customerInfo[0].lastName,
            storeMerchantId: merchentIdData[0].storeMerchantId || null,
            placeOrderDetails: processOrderData,
            coWorkerId: String(empId),
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            customerId: String(customer),
            paymentOrigin: (props.PayOrigin != 'Store'&&key==String(window.sessionStorage.getItem("storeNumber")))?"H":"S",
            exceededFTAmount: props?.exceededFTAmount > 0 ? parseFloat(props?.exceededFTAmount?.toFixed(2)) : null,
            clubPayment: clubPayload.length &&!(key==String(window.sessionStorage.getItem('storeNumber'))&& props.clubActivate == 0)  ? clubPayload : undefined,
            agreementPayment: agreementPaymentPayload.length
              ? agreementPaymentPayload
              : undefined,
            transferSuspense: transferSuspensePayload.length
              ? transferSuspensePayload
              : undefined,
            miscPayment: miscPaymentPayload.length
              ? miscPaymentPayload
              : undefined,
            paymentMethod: {
              tenderDetails: tenderDetailsPayload.length
                ? tenderDetailsPayload
                : undefined,
              cardDetails: cardDetailsPayload.length
                ? cardDetailsPayload
                : undefined,
              customerAccount: customerAccountPayload.length
                ? customerAccountPayload
                : undefined,
            },
            deactivateClub:
              key==String(window.sessionStorage.getItem('storeNumber'))&& props.clubActivate == 0 ? [String(customer)] : undefined,
          },
        ],
      });
      groupByStoreNumber[key].map((el) => {
        console.log('keyvalueinel', el);
      });
      console.log('foreachkeyvalue', groupByStoreNumber[key]);
    });

    let finalResult: any = [];
    await Promise.all(
      entireAcceptPayAPIArray.map(async (el, index) => {
        const resultOfDoct: any = await ProcessPaymentAPI(el);
        console.log('sdcvsdfcewfsd', resultOfDoct);
        if (resultOfDoct.status == 200) {
          resultOfDoct.data.receiptInfo.map((rcpel) => {
            finalResult.push({
              storeNumber: collectStoreNumber[index],
              receiptID: rcpel.receiptId,
              isPaymentFailed: false,
            });
          });
          if (retentionType && el.paymentInfo[0].agreementPayment) {
            const filterAppliedFreeTime =
              el.paymentInfo[0].agreementPayment.filter(
                (el) =>
                  el.freeTime !== undefined &&
                  el.agreementId == location?.state?.retentionData?.agreementId
              );
            const filterAppliedEPO = el.paymentInfo[0].agreementPayment.filter(
              (el) =>
                el.epoPayment !== undefined &&
                el.agreementId == location?.state?.retentionData?.agreementId
            );
            const today = new Date();
            const date = today.toISOString().split('T')[0];
            if (filterAppliedFreeTime.length) {
              const freeTimeAgrActivityPayload = {
                agreementActivities: [
                  {
                    activityDate: date,
                    agreementId: filterAppliedFreeTime[0].agreementId,
                    activityDescription: 'Agr Retention Extension',
                    agreementActivityType: 'AGMRETEXT',
                  },
                ],
              };
              await postAgreementActivity(freeTimeAgrActivityPayload);
            } else if (filterAppliedEPO.length) {
              const EPOAgrActivityPayload = {
                agreementActivities: [
                  {
                    activityDate: date,
                    agreementId: filterAppliedEPO[0].agreementId,
                    activityDescription: 'Agr Retention EPO',
                    agreementActivityType: 'AGMEPORET',
                  },
                ],
              };
              await postAgreementActivity(EPOAgrActivityPayload);
            }
          }
          if (props?.exchangeAgreementNumber && index == 0) {
            unEnrollAutoPay();
          }
        } else {
          finalResult.push({
            storeNumber: collectStoreNumber[index],
            receiptID: '',
            isPaymentFailed: true,
          });
        }
      })
    );

    let paymentAPIResult: any = [];
    let receiptConditionData: any = [];
    finalResult.map((el) => {
      paymentAPIResult.push({
        storeNumber: el.storeNumber,
        transactionStatus: el.isPaymentFailed ? 'Failed' : 'Passed',
      });
      receiptConditionData.push({
        documentType: '',
        documentURL: '',
        storeNumber: el.storeNumber,
        isPaymentFailed: el.isPaymentFailed,
        isLoading: true,
      });
    });
    const StoreExceptionRequest = updatedPayload
    const currentStoreReceiptId = finalResult?.filter((obj: any) => obj?.storeNumber == window.sessionStorage.getItem('storeNumber'))
    if (currentStoreReceiptId[0]?.isPaymentFailed == false) {
      const StoreExceptionRequest = updatedPayload

      if (finalResult && StoreExceptionRequest) {
        const payload = StoreExceptionRequest.map((obj) => {
          obj.identifierId = Number(currentStoreReceiptId[0]?.receiptID)
        })

        let epoExtensionPayload: any
        let freeDaysExtension: any
        let finalPayload: any=[]
        epoExtensionPayload = StoreExceptionRequest.filter((obj) => obj?.exceptionTypeRefCode != 'REX')
        freeDaysExtension = StoreExceptionRequest.filter((obj) => obj?.exceptionTypeRefCode == 'REX')

        if (StoreExceptionRequest?.some((obj) => obj?.exceptionTypeRefCode == 'REX')) {
          rentalExtensionAgr.map((obj) => {
            finalPayload.push({
              "identifierId": Number(currentStoreReceiptId[0]?.receiptID),
              "identifierType": 'RECEIPT',
              "storeNumber": freeDaysExtension[0]?.storeNumber,
              "exceptionTypeRefCode": "REX",
              "exceptionText": freeDaysExtension[0]?.exceptionText,
              "customerId": Number(customerId),
              "agreementId": Number(obj.Agreementid)
            })
          })
        }
        finalPayload = [...finalPayload, ...epoExtensionPayload]
        const Request = {
          storeException: finalPayload
        }
        const StoreExceptionRes = storeException(Request)
        setStoreExceptionPayload([])
      }
      // if (finalResult && StoreExceptionRequest) {
      //   const payload = StoreExceptionRequest.map((obj) => {
      //     obj.identifierId = Number(currentStoreReceiptId[0]?.receiptID)
      //   })
      //   const Request = {
      //     storeException: StoreExceptionRequest
      //   }
      //   const StoreExceptionRes = storeException(Request)
      //   setStoreExceptionPayload([])
      // }
    }

    settransactionResultArray(paymentAPIResult);
    invokeReceiptParallel(finalResult);
    setFinalPaymentResult(finalResult);
    setInitialReceiptStatus(receiptConditionData);
    setreceiptResultArray(receiptConditionData);
    setload(false);
    setShowPaymentStatus(true);
  };
  const invokeCOReceipt = async (receiptData: any) => {
    console.log('receiptData in increcp', receiptData);
    // setload(true);
    setFetchReceipt(false);
    let isAnyFailed: any = [];
    let collectedURL: any = [];
    let receiptTimeOut = true;
    setTimeout(async function () {
      receiptTimeOut = false;
    }, 25000);

    console.log('reachefsdkj', coReceiptFailedAttempt, isAnyFailed);
    for (let i = 0; i < receiptData.data.receiptInfo?.length; i++) {
      collectedURL.push({
        receiptId: receiptData.data.receiptInfo[i].receiptId,
        blobData: '',
      });
    }
    setReceiptURLArray(collectedURL);
    setFetchReceipt(false);
    while (receiptTimeOut) {
      // invokeCOReceipt(receiptData);
      await Promise.all(
        receiptData.data.receiptInfo.map(async (el) => {
          const resultOfDoct: any = await getDocument(el.receiptId, 'RCP');
          console.log('resultOfDoct in co rdp', resultOfDoct);
          if (
            resultOfDoct.status == 200 &&
            Object.keys(resultOfDoct.data).length > 0 &&
            resultOfDoct.data.fileList[0].fileObject !== null
          ) {
            let Receiptrecord = resultOfDoct.data.fileList.filter(
              (x) => x.docType === 'PRCT'
            );
            if (Receiptrecord.length != 0) {
              const type = getContentType(Receiptrecord[0]?.fileName);
              const formatedURL = URL.createObjectURL(
                b64toBlob(Receiptrecord[0]?.fileObject, type)
              );
              let index = collectedURL.findIndex(
                (x) => String(x.receiptId) === String(el.receiptId)
              );
              collectedURL[index].blobData = formatedURL;
              setReceiptURLArray(collectedURL);
            }
            let blobCOunt = 0;
            for (let i; i < collectedURL?.length; i++) {
              if (collectedURL[i].blobData != '') {
                blobCOunt++;
              }
            }
            setreceipturlCO([resultOfDoct]);
            if (blobCOunt == collectedURL?.length) {
              receiptTimeOut = false;
            }
            // settran(true);
          }
        })
      );
    }
    //settran(true);
    setReceiptURLArray(collectedURL);
    // coReceiptFailedAttempt = 0;
    setFetchReceipt(true);
    // setload(false);
    console.log('asdf', collectedURL);
  };

  const MakepaymentApiCall = async () => {
    props.setpaymentMade(true);
    let cardlastFour: any = lastFour === '' ? undefined : lastFour;
    /* eslint-disable no-console */
    console.log('getcardResponse response----->', getcardResponse);
    if (actLDWArr.length > 0) {
      for (let i = 0; i < actLDWArr.length; i++) {
        // for(let i=0;i<actLDWArr.length;i++){

        // }
        let lDWRequest = {
          agreementId: actLDWArr[i].agreementId,
          action: actLDWArr[i].action,
        };
        const ldwAPIResult = await LDwServiceCall(lDWRequest);
        if (ldwAPIResult.status === 200) {
          AcPayment.map((obj) => {
            if (
              obj.agreementId == actLDWArr[i].agreementId &&
              actLDWArr[i].action == 1
            ) {
              obj.ldwActivate = 1;
            } else if (
              obj.agreementId == actLDWArr[i].agreementId &&
              actLDWArr[i].action == 2
            ) {
              obj.ldwActivate = 0;
            }
          });
        }
        if (actLDWArr[i].action == 2) {
          actLDWArr[i].action = 1;
        } else {
          actLDWArr[i].action = 2;
        }
      }
    }
    if (actPolicyArr.length > 0) {
      for (let i = 0; i < actPolicyArr.length; i++) {
        let PolicyRequest = {
          agreementId: actPolicyArr[i].agreementId,
          action: actPolicyArr[i].action,
        };
        await PolicyServiceCall(PolicyRequest);
        if (actPolicyArr[i].action == 2) {
          actPolicyArr[i].action = 1;
        } else {
          actPolicyArr[i].action = 2;
        }
      }
    }
    let documentDetail: any = [];
    for (let i = 0; i < checkDetail.length; i++) {
      let documentObj = {
        documentAmount: Number(checkDetail[i].Amount_rac),
        documentType: checkDetail[i].Type_info == 'Check' ? 'CHK' : 'MO',
        documentNumber: checkDetail[i].check_no,
        documentIssuer: checkDetail[i].Written_by,
        documentPayee: checkDetail[i].Written_too,
      };
      documentDetail.push(documentObj);
    }
    const customerInfoParam: CustomerInfo = {
      FirstName: getPaymentInfoResp.paymentInfo.customerInfo[0].firstName,
      LastName: getPaymentInfoResp.paymentInfo.customerInfo[0].lastName,
    };
    let cardInfoParam: any = [];
    let BillAddressInfoParam: any;
    let chargeCardFlag = 0;
    let cardDetals: any = [];
    // if (
    if (
      getcardResponse.CardInfo !== null &&
      getcardResponse.CardInfo !== undefined
    ) {
      // cardlastFour = getcardResponse.CardInfo.CardLastFour;
      cardInfoParam = getcardResponse.CardInfo.filter(
        (e) => Number(e.Amount) > 0 && e.cvvEncrypt.length >= 3
      );
      if (chargeCardArray.length > 0) {
        cardInfoParam = [...cardInfoParam, ...chargeCardArray];
      }
      // let cardDetail = {
      //   cardAmount: Number(DecimalVal),
      //   cardAuthNumber: "",
      //   cardLastFour: lastFour,
      //   cardType: cardType,
      //   cardToken: cardToken,
      // };
      for (let i = 0; i < cardInfoParam.length; i++) {
        // const cardtoken:any = cardToken.length==0?"":cardToken.filter((e) => String(cardInfoParam[i].lastFour)==String(e.lastFour));
        cardDetals.push({
          cardAmount: Number(cardInfoParam[i].Amount),
          cardAuthNumber: '',
          cardLastFour: cardInfoParam[i].lastFour,
          cardType: cardInfoParam[i].cardType,
          cardToken: cardInfoParam[i].cardToken,
          cardTransactionType:
            cardInfoParam[i].chargeCard == false ? 'PAYTOK' : 'MNL',
        });
        cardInfoParam[i].billingAddress.addressLine2 =
          cardInfoParam[i].billingAddress.addressLine2 != null
            ? cardInfoParam[i].billingAddress.addressLine2
            : '';
      }
      for (let i = 0; i < cardDetail.length; i++) {
        cardDetals.push({
          cardAmount: Number(cardDetail[i].cardAmount),
          cardAuthNumber: cardDetail[i].cardAuthNumber,
          cardLastFour: cardDetail[i].cardLastFour,
          cardType: cardDetail[i].cardType,
          cardToken: cardDetail[i].cardToken,
          cardSequence: cardDetail[i].cardSequence,
          cardTransactionType: 'SWP',
        });
        //cardDetail[i].billingAddress.addressLine2=cardDetail[i].billingAddress.addressLine2!=null?cardDetail[i].billingAddress.addressLine2:""
      }
      let mescellineasArrayReceipt: any = [];
      //  debugger;
      for (let i = 0; i < tenderTypeArray.length; i++) {
        // props.paygridvalues.filter((e) => String(cardInfoParam[i].lastFour)==String(e.lastFour));
        const misItemArray = props.paygridvalues.filter(
          (x: any) =>
            Number(
              tenderTypeArray[i].agreement_id.split('Miscellaneous Item ')[1]
            ) == Number(x.miscellaneousItemsNum)
        );
        // if(Number((tenderTypeArray[i].agreement_id).split("Miscellaneous Item ")[0].includes("Miscellaneous Item ")))
        if (misItemArray.length > 0) {
          let ReceiptArray: any = {
            customerId: null,
            schedule: '',
            amountDue: 0,
            ldwFeeAmount: 0,
            clubPayment: 0,
            clubPaymentTax: 0,
            creditOnAccountAdd: 0,
            creditOnAccountSub: Number(misItemArray[0].COA),
            integratedPaymentSuspense: 0,
            initialPayment: 0,
            netLateFee: 0,
            carriedLateFee: 0,
            wlDeposit: 0,
            htSuspense: 0,
            suspenseAdd: 0,
            suspenseSub: 0, //Number( payGridObj[0].suspense),
            racTirePay: 0,
            agreementId: null,
            change: 0,
            totalTax: 0,
            sac: 0,
            sacTax: 0,
            ldwFeeAmountTax: 0,
            rentalPaymentTax: 0,
            lateRentalPaymentfeeTax: 0,
            lateRentalPaymentfee: 0,
            racTirePayTax: 0,
            deliveryFee: 0,
            deliveryFeeTax: 0,
            processingFee: 0,
            processingFeeTax: 0,
            //otherFee: Number( payGridObj[0].mattressFee),
            //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
            pif: 0,
            pifTax: 0,
            extensionAmount: 0,
            convenienceFee: 0,
            convenienceFeeTax: 0,
            mattressFee: 0,
            nineOneOneFee: Number(misItemArray[0].nineOneOneFee),
            cellPhoneActivation: Number(misItemArray[0].cellPhoneActivation),
            cellPhoneActivationTax: Number(
              misItemArray[0].cellPhoneActivationTax
            ),
          };
          mescellineasArrayReceipt.push(ReceiptArray);
          const miscArray = {
            terminalId: misItemArray[0].miscellaneousItemInfo.terminalId,

            sessionId: misItemArray[0].miscellaneousItemInfo.sessionId,

            actionId: misItemArray[0].miscellaneousItemInfo.actionId,

            saleId: misItemArray[0].miscellaneousItemInfo.saleId,

            requestId: misItemArray[0].miscellaneousItemInfo.requestId,

            sequenceNumber: misItemArray[0].miscellaneousItemInfo.apiCalc,

            txnId: misItemArray[0].miscellaneousItemInfo.txnId,

            productId: misItemArray[0].miscellaneousItemInfo.productId,

            amount: misItemArray[0].miscellaneousItemInfo.amount,

            shopId: misItemArray[0].miscellaneousItemInfo.shopId,

            cashier: misItemArray[0].miscellaneousItemInfo.cashier,

            consumerDiscount:
              misItemArray[0].miscellaneousItemInfo.consumerDiscount ==
                undefined
                ? '0.00'
                : misItemArray[0].miscellaneousItemInfo.consumerDiscount,

            serviceFee:
              misItemArray[0].miscellaneousItemInfo.serviceFee == undefined
                ? '0.00'
                : misItemArray[0].miscellaneousItemInfo.serviceFee,

            productName: String(misItemArray[0].miscellaneousItemInfo.type),

            saleAmount: String(
              misItemArray[0].miscellaneousItemInfo.salesPrice
            ),

            mobileNumber: String(
              misItemArray[0].miscellaneousItemInfo.phoneNumber
            ),

            carrier: String(misItemArray[0].miscellaneousItemInfo.carrierName),

            pricePlan: String(
              misItemArray[0].miscellaneousItemInfo.description
            ),

            totalSale: String(misItemArray[0].miscellaneousItemInfo.totalSales),

            simNumber: String(misItemArray[0].miscellaneousItemInfo.simNumber),

            customData: misItemArray[0].miscellaneousItemInfo.data,
          };
          AcceptMiscArray.push(miscArray);
        }
      }
      receiptCatArray = [...ReceiptCatogory, ...mescellineasArrayReceipt];

      // if (getcardResponse.CardInfo.BillingAddress != undefined) {
      //   BillAddressInfoParam = {
      //     Address1: getcardResponse.CardInfo.BillingAddress.addressLine1,
      //     Address2: getcardResponse.CardInfo.BillingAddress.addressLine2 !== null ? getcardResponse.CardInfo.BillingAddress.addressLine2 : '',
      //     City: getcardResponse.CardInfo.BillingAddress.city,
      //     StateProvince: getcardResponse.CardInfo.BillingAddress.state,
      //     PostalCode: getcardResponse.CardInfo.BillingAddress.zip,
      //     Country: 'USA',
      //     BillShipSameAddressFlag: "0",
      //   };
      // }
    }
    if (billingAddressCharge != undefined) {
      BillAddressInfoParam = billingAddressCharge;
      chargeCardFlag = 1;
    }
    let amount =
      cardInfoParam.length == 0
        ? 0.0
        : cardInfoParam
          .map((bill) => bill.Amount)
          .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let cardFlag = '0';
    if (amount > 0) {
      cardFlag = '1';
    }

    if (swipeFlag) {
      cardlastFour = lastFour;
      amount = Number(swipeCardAmt);
    }
    let transactionDate = new Date();
    for (let i = 0; i < cardDetail.length; i++) {
      if (cardDetail[i].cardAmount == 0) {
        cardDetail.splice(i, 1);
      }
    }

    for (let i = 0; i < ReceiptCatogory.length; i++) {
      ReceiptCatogory[i].schedule = ReceiptCatogory[i].schedule.split('|')[0];
    }
    let finalConfirm = 0;
    for (let i = 0; i < props.ClubPayFlag?.length; i++) {
      if (
        props.ClubPayFlag[i].Nextduedate == 'PIF' ||
        props.ClubPayFlag[i].Nextduedate == 'SAC' ||
        props.ClubPayFlag[i].Nextduedate == 'EPO'
      ) {
        finalConfirm = 1;
        finalagr = Number(props.ClubPayFlag[i].Agreementid);
      }
    }
    if (finalConfirm != 1) {
      finalagr = undefined;
    }
    let webleadAmount: any = [];
    let creditOnAccount: any = [];
    let IPSuspense: any = [];

    if (props.availableCredsremaining.length > 0) {
      webleadAmount = props.availableCredsremaining.filter(
        (x) => x.balanceType === 'WEBLEAD'
      );
      creditOnAccount = props.availableCredsremaining.filter(
        (x) => x.balanceType === 'COA'
      );
      IPSuspense = props.availableCredsremaining.filter(
        (x) => x.balanceType === 'IP Suspence'
      );
    }
    let suspenseUsed = 0;
    if (receiptCatArray?.length > 0) {
      for (let i = 0; i < receiptCatArray?.length; i++) {
        suspenseUsed = suspenseUsed + Number(receiptCatArray[i].suspenseSub);
      }
    }
    let transferSuspense: any = [];
    for (let i = 0; i < props?.paygridvalues?.length; i++) {
      if (
        Number(props?.paygridvalues[i].SuspenseAmount) !=
        Number(props?.paygridvalues[i].transferOldSuspense)
      ) {
        transferSuspense.push({
          currentBalance: Number(
            props?.paygridvalues[i].transferOldSuspense
          ).toFixed(2),
          newSuspenseBalance: Number(
            props?.paygridvalues[i].SuspenseAmount
          ).toFixed(2),
          agreementId: String(props?.paygridvalues[i].Agreementid),
          isTransferToCoa: props?.paygridvalues[i].isCOATransfered,
        });
      }
    }
    let totalDue: any = totalDueamt;
    const finalPaymentSuspenseuse = props.ClubPayFlag?.filter(
      (e) => Number(e.acceptEpoFlag) == 1 && Number(e.suspenseSub) > 0
    );
    for (let i = 0; i < finalPaymentSuspenseuse?.length; i++) {
      totalDue =
        Number(totalDue) + Number(finalPaymentSuspenseuse[i].suspenseSub);
    }
    let processOrderData: any = undefined;
    if (
      props.isCoPrelim &&
      (props.provideEAFlag == null || props.provideEAFlag.toLowerCase() == 'no')
    ) {
      processOrderData = {
        eaFlag: 'No',
        agreementNumber: props.provideAgrNumberPO,
      };
    } else if (props.isCoPrelim && props.provideEAFlag.toLowerCase() == 'yes') {
      const updatedPlaceOrderAddressData = props.providePOAddressData;
      // updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
      processOrderData = {
        eaFlag: 'Yes',
        agreementNumber: props.provideAgrNumberPO,
        shippingAddress: updatedPlaceOrderAddressData,
        items: props.providePlaceOrderItemData
      };
    }

    const addSuspenseAmount = props?.totalsuspensemaster?.filter(function (e) {
      return e.sustype == 'Add' && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = props?.totalsuspensemaster?.filter(function (e) {
      return e.sustype == 'Used' && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount?.length == 0
        ? 0.0
        : addSuspenseAmount
          ?.map((bill) => bill.newsuspense)
          ?.reduce((acc, bill) => (Number(bill) + Number(acc))?.toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount?.length == 0
        ? 0.0
        : useSuspenseAmount
          ?.map((bill) => bill.newsuspense)
          ?.reduce((acc, bill) => (Number(bill) + Number(acc))?.toFixed(2));

    let totalAmountCollected =
      Number(totalDue) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) <
        0
        ? 0
        : Number(
          (
            Number(totalDue) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        );

    const MakePaymentParam: MakePaymentDTO = {
      raftApiFlag: Number(props.configFlags.raftApiFlag),
      transferSuspense: transferSuspense,
      storeMerchantId: props.storeMerchantId || null,
      miscellaneousArray: AcceptMiscArray,
      recieptCategoryReqDetails: receiptCatArray,
      storeNumber: String(window.sessionStorage.getItem('storeNumber')),
      customerId: String(customer),
      storeCoWorkerId: String(empId),
      paymentOriginName: PaymentOrigin,
      totalAmountCollected: Number(Number(totalAmountCollected).toFixed(2)),
      totalTaxCollected: getPaymentInfoResp.totalTax,
      paymentId: String(
        getcardResponse.CardInfo !== null &&
          getcardResponse.CardInfo !== undefined &&
          swipeFlag !== true
          ? getcardResponse.CardInfo.customerPaymentMethodId
          : 0
      ),
      carryAmount: Number(carryAmount),
      taxRate: Number(props.paygridvalues[0].taxRate),
      cardAmount: Number(amount == null ? 0 : amount),
      cashAmount:
        props.throwPaymentAtOrder !== undefined
          ? Number(
            (
              Number(props.throwPaymentAtOrder) +
              Number(getcardResponse.CashAmount)
            ).toFixed(2)
          )
          : getcardResponse.CashAmount,
      moneyOrderAmount: getcardResponse.MOAmount,
      checkAmount: getcardResponse.ChequeAmount,
      phoneNumber:
        getPaymentInfoResp.paymentInfo.customerInfo[0].phoneNumber == null ||
          undefined
          ? ''
          : getPaymentInfoResp.paymentInfo.customerInfo[0].phoneNumber,
      transactionDate: String(transactionDate),
      customerZip:
        getPaymentInfoResp.paymentInfo.customerInfo[0].zip +
        '-' +
        getPaymentInfoResp.paymentInfo.customerInfo[0].plus4,
      customerState: getPaymentInfoResp.paymentInfo.customerInfo[0].state,
      customerDOB: '02/22/1950',
      chargeCardFlag: chargeCardFlag,
      //paymentAmount: Number(Number(totalDueamt).toFixed(2)),
      cardDetails: cardDetals,
      documentDetails: documentDetail,
      paymentAmount: Number(
        getcardResponse.CashAmount +
        getcardResponse.MOAmount +
        getcardResponse.ChequeAmount +
        Number(amount == null ? 0 : amount) +
        getcardResponse.AvailableCredits +
        Number(
          props.throwPaymentAtOrder !== undefined
            ? props.throwPaymentAtOrder
            : 0
        )
      ),
      cardFlag: cardFlag,
      emailAddress:
        getPaymentInfoResp.paymentInfo.customerInfo[0].emailAddress == null ||
          undefined
          ? ''
          : getPaymentInfoResp.paymentInfo.customerInfo[0].emailAddress,
      originFee: '0.00',
      totalAmountDue: Number(Number(totalAmountCollected).toFixed(2)),
      wlDeposit:
        webleadAmount.length > 0
          ? Number(webleadAmount[0].accountBalance)
          : 0.0,
      itSuspence:
        IPSuspense.length > 0 ? Number(IPSuspense[0].accountBalance) : 0.0,
      suspense: Number(props.Suspense),
      changeFlag: radioFlags,
      taxGeoCodeId: taxGeoCode,
      zipPlus4UsedForTax: taxZip,
      customerInfo: customerInfoParam,
      cardInfo: cardInfoParam,
      // billToAddresInfo: BillAddressInfoParam,
      creditOnAccount:
        creditOnAccount.length > 0
          ? Number(creditOnAccount[0].accountBalance)
          : 0.0,
      // change: Number( getcardResponse.CashAmount+
      //   getcardResponse.MOAmount+
      //   getcardResponse.ChequeAmount +
      //   0.00) - Number(Number(totalDueamt).toFixed(2)),
      change:
        radioFlags == '0'
          ? Number(Number(fixedChange(Changes)).toFixed(2))
          : 0.0,
      acceptPayment: AcPayment,
      clubActivate: props.clubActivate,
      acceptClubPayment: ClubAcPayment,
      cardAuthNumber: cardAuthNo,
      cardLastFour: cardlastFour,
      placeOrderDetails: processOrderData,
      // cardToken: (cardFlag == '1' && getcardResponse.CardInfo.BillingAddress != undefined ? getcardResponse.CardInfo.BillingAddress.cardToken : undefined)
    };
    MakePaymentParam?.acceptPayment?.forEach((item, index) => {
      const itemPaygrid = props.paygridvalues.filter(
        (e) => String(item.agreementId) == String(e.Agreementid)
      );
      item.lateFeeWaivedAmount =
        Number(itemPaygrid[0].waivedLateFee) == 0
          ? undefined
          : Number(itemPaygrid[0].waivedLateFee).toFixed(2);
    }),
      MakePaymentParam.recieptCategoryReqDetails.forEach((item, index) => {
        let susAmt = 0;
        let coaAmt = 0;
        let changeNum = Number(Number(Changes).toFixed(2));
        const installmentNoteAgrFilter = props.paygridvalues.filter(
          (e) =>
            Number(e.agreementNoteDetails.isNoteAgreement) == 1 &&
            String(item.agreementId) == String(e.Agreementid)
        );
        const itemPaygrid = props.paygridvalues.filter(
          (e) => String(item.agreementId) == String(e.Agreementid)
        );
        item.suspenseSub = itemPaygrid[0]?.suspenseSub;
        if (installmentNoteAgrFilter?.length > 0) {
          if (installmentNoteAgrFilter[0].Agreementtype == 'Note Payment') {
            item.installmentNote = {
              type: 'Note',

              amount: Number(item.amountDue).toFixed(2),

              taxAmount: '0.00',
            };
            item.amountDue = 0.0;
            item.rentalPaymentTax = 0.0;
          }
          if (
            (installmentNoteAgrFilter[0].Nextduedate == 'SAC' ||
              installmentNoteAgrFilter[0].Nextduedate == 'EPO' ||
              installmentNoteAgrFilter[0].Nextduedate == 'PIF') &&
            installmentNoteAgrFilter[0].acceptEpoFlag == 0
          ) {
            // item.amountDue=0.00
            // item.rentalPaymentTax=0.00

            if (installmentNoteAgrFilter[0].Nextduedate == 'SAC') {
              item.sac = Number(
                (
                  Number(item.sac) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),
              };
            } else if (installmentNoteAgrFilter[0].Nextduedate == 'EPO') {
              item.epo = Number(
                (
                  Number(item.epo) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),
              };
            } else if (installmentNoteAgrFilter[0].Nextduedate == 'PIF') {
              item.pif =
                installmentNoteAgrFilter[0].Agreementtype == 'PIF NOTE'
                  ? 0.0
                  : Number(
                    (
                      Number(item.pif) -
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .NotAmountFinal
                      )
                    ).toFixed(2)
                  );
              if (installmentNoteAgrFilter[0].Agreementtype == 'PIF NOTE') {
                item.amountDue = 0.0;
                item.rentalPaymentTax = 0.0;
              }
              if (
                Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .NotAmountFinal
                ) > 0 &&
                installmentNoteAgrFilter[0].Agreementtype == 'PIF NOTE'
              ) {
                item.installmentNote = {
                  type:
                    installmentNoteAgrFilter[0].Agreementtype == 'PIF NOTE'
                      ? 'PIF Note'
                      : installmentNoteAgrFilter[0].Nextduedate,

                  amount: Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ).toFixed(2),

                  taxAmount: '0.00',
                };
              }

              if (
                Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .NotAmountFinal
                ) > 0 &&
                installmentNoteAgrFilter[0].Agreementtype == 'PIF Rent' &&
                installmentNoteAgrFilter[0].Nextduedate == 'PIF'
              ) {
                item.installmentNote = {
                  type: 'Note',
                  amount: Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .NotAmountFinal
                  ).toFixed(2),

                  taxAmount: '0.00',
                };
              }
            }
          } else if (
            (installmentNoteAgrFilter[0].Nextduedate == 'SAC' ||
              installmentNoteAgrFilter[0].Nextduedate == 'EPO' ||
              installmentNoteAgrFilter[0].Nextduedate == 'PIF') &&
            installmentNoteAgrFilter[0].acceptEpoFlag == 1
          ) {
            if (
              installmentNoteAgrFilter[0].Nextduedate == 'SAC' &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) != 0
            ) {
              // let epoAmount=Number(((Number(item.sac)-Number(item.pastDueAmount)+Number(item.pastDueDiscount==undefined?0:item.pastDueDiscount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))<0?0.00:(Number(item.sac)-Number(item.pastDueAmount)- Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))).toFixed(2))
              // item.sac=Number((Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingAgreementCost)+Number(item.pastDueAmount)-Number(item.pastDueDiscount)).toFixed(2))
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                  0
                  ? 0.0
                  : Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              item.sac = Number(
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingAgreementCost
                  ) -
                  Number(item.epoDiscount) -
                  Number(item.pastDueDiscount)
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == '0.00'
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .remainingNoteCostAmount
                      ) -
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .editedNoteAmount
                      )
                    ).toFixed(2)
                    : '0.00'
                );
              }
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),

                discount: Number(noteDiscount).toFixed(2),
              };
            } else if (
              installmentNoteAgrFilter[0].Nextduedate == 'EPO' &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) != 0
            ) {
              // let epoAmount=Number(((Number(item.epo)-Number(item.pastDueAmount)+Number(item.pastDueDiscount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))<0?0.00:(Number(item.epo)-Number(item.pastDueAmount)- Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))).toFixed(2))
              // item.epo=Number((Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingAgreementCost)+Number(item.pastDueAmount)-Number(item.pastDueDiscount)).toFixed(2))
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                  0
                  ? 0.0
                  : Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              item.epo = Number(
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingAgreementCost
                  ) -
                  Number(item.epoDiscount) -
                  Number(item.pastDueDiscount)
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == '0.00'
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .remainingNoteCostAmount
                      ) -
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .editedNoteAmount
                      )
                    ).toFixed(2)
                    : '0.00'
                );
              }
              item.installmentNote = {
                type:
                  installmentNoteAgrFilter[0].Agreementtype == 'Epo Note'
                    ? 'EPO Note'
                    : 'EPO',

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),

                discount: Number(noteDiscount).toFixed(2),
              };
            }
            if (
              installmentNoteAgrFilter[0].Nextduedate == 'SAC' &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) == 0
            ) {
              item.sac = Number(
                (Number(item.sac) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                  0
                  ? 0.0
                  : Number(item.sac) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                  0
                  ? 0.0
                  : Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == '0.00'
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .remainingNoteCostAmount
                      ) -
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .editedNoteAmount
                      )
                    ).toFixed(2)
                    : '0.00'
                );
              }
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCostAmount
                ).toFixed(2),

                taxAmount: '0.00',

                discount: Number(noteDiscount).toFixed(2),
                //Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)>0?(Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)).toFixed(2):"0.00",
              };
              item.amountDue = 0.0;
              item.rentalPaymentTax = 0.0;
            } else if (
              installmentNoteAgrFilter[0].Nextduedate == 'EPO' &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) == 0
            ) {
              item.epo = Number(
                (Number(item.epo) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                  0
                  ? 0.0
                  : Number(item.epo) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                  0
                  ? 0.0
                  : Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == '0.00'
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .remainingNoteCostAmount
                      ) -
                      Number(
                        installmentNoteAgrFilter[0].agreementNoteDetails
                          .editedNoteAmount
                      )
                    ).toFixed(2)
                    : '0.00'
                );
              }
              item.installmentNote = {
                type:
                  installmentNoteAgrFilter[0].Agreementtype == 'Epo Note'
                    ? 'EPO Note'
                    : 'EPO',

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCostAmount
                ).toFixed(2),

                taxAmount: '0.00',

                discount: Number(noteDiscount).toFixed(2),
              };
              item.amountDue = 0.0;
              item.rentalPaymentTax = 0.0;
            }
          }
        }

        if (
          radioFlags == '1' &&
          Number(Changes) > 0 &&
          Number(Changes) >=
          Number(
            Number(totalDueamt) -
            Number(avaliableCredits) -
            Number(carryAmount)
          )
        ) {
          susAmt = radioFlags == '1' && changeNum >= 0 ? changeNum : 0;
        }

        if (
          radioFlags == '1' &&
          Number(Changes) > 0 &&
          props.suspensehide == false &&
          props.CreditRadio == true
        ) {
          coaAmt = radioFlags == '1' && changeNum >= 0 ? changeNum : 0;
        }
        item.change = Number((0).toFixed(2));
        //Web lead
        if (webleadAmount.length > 0) {
          item.wlDeposit = -1 * Number(webleadAmount[0].accountBalance);
        } else {
          item.wlDeposit = 0;
        }

        // COA
        if (creditOnAccount.length > 0) {
          item.creditOnAccountSub = Number(creditOnAccount[0].accountBalance);
        } else {
          item.creditOnAccountSub = 0;
        }

        //IP Suspense
        if (IPSuspense.length > 0) {
          item.integratedPaymentSuspense =
            -1 * Number(IPSuspense[0].accountBalance);
        } else {
          item.integratedPaymentSuspense = 0;
        }

        item.creditOnAccountAdd = coaAmt;
        // if(radioFlags == "1" && changeNum >= 0 &&Number(item.suspenseAdd)==0){
        //   item.suspenseAdd = susAmt;
        // }
        // item.suspenseAdd = susAmt;
        if (PaymentOrigin == 'Pay By Phone') {
          item.convenienceFee = Number(props.ConvFeeWithOutTax);
          item.convenienceFeeTax = convTax;
        }
      });
    let acceptPaymentApi;
    let acceptApiCall = await MakePayment(MakePaymentParam);

    setMakepaymentResp(acceptApiCall);
    props.setAcceptpaymentbuttonenable(true);
    if (acceptApiCall.status === 200) {
      acceptPaymentApi = await AcceptPaymentApiCall(acceptApiCall);
      // if(acceptPaymentApi.status===400){
      //   if(acceptPaymentApi.errors[0].error=="Failed to do the cellphone activation"){
      //     setepayFail("Failed to do the cellphone activation")
      //   }
      // }
    } else if (acceptApiCall.status === 403 && acceptApiCall.status !== 200) {
      setTranFail(true);
      setTranFailtxtchange(true);
      setload(false);
      // if (actLDWArr.length > 0) {
      //   for (let i = 0; i < actLDWArr.length; i++) {
      //     await LDwServiceCall(actLDWArr[i]);
      //     if (actLDWArr[i].action == 1) {
      //       ActClubApiCall(actLDWArr[i].agreementId, actLDWArr[i].action);
      //     }
      //   }
      // }
    } else {
      setTranFail(true);
      setTranFailtxtchange(false);
      setload(false);
      // if (actLDWArr.length > 0) {
      //   for (let i = 0; i < actLDWArr.length; i++) {
      //     await LDwServiceCall(actLDWArr[i]);
      //     if (actLDWArr[i].action == 1) {
      //       ActClubApiCall(actLDWArr[i].agreementId, actLDWArr[i].action);
      //     }
      //   }
      // }
    }
    /* eslint-disable no-console */
    console.log('AcceptPayment response----->', acceptApiCall);
  };
  const getCustDetail = () => {
    let arr: any = [];
    if (props?.customerData?.data?.GetCustomer?.value) {
      let obj: any = {
        customerId: props?.customerData?.data?.GetCustomer?.value?.customerId,
        customerName:
          props?.customerData?.data?.GetCustomer?.value.firstName +
          ' ' +
          props?.customerData?.data?.GetCustomer?.value.lastName,
        phoneNumber:
          props?.customerData?.data?.GetCustomer?.value?.phones[0]?.phoneNumber,
        emailId: props?.customerData?.data?.GetCustomer?.value?.emailAddress,
      };
      arr.push(obj);
    }
    return arr;
    // setcustomerData(arr)
  };
  const fixedChange = (value: string) => {
    if (value == undefined || value == null || parseFloat(value) < 0.0) {
      return '0.00';
    } else {
      return String(parseFloat(value).toFixed(2));
    }
  };
  const AgreementCustApiCall = async () => {
    console.log(
      getPaymentInfoResp.paymentInfo.customerInfo[0].salesLead,
      'sales lead accept payment'
    );
    if (getPaymentInfoResp.paymentInfo.customerInfo[0].salesLead !== null) {
      // let schedule = AgreementIdList[i].schedule.split("|");
      // let agrNumber = "";
      // if (schedule.length == 2) {
      //   agrNumber = schedule[1];
      // }
      let agreementObj = getPaymentInfoResp.paymentInfo.paymentInfo.filter(
        (e) => (e.agreementId = Agreement)
      );
      let salesLeadReq: salesLeadRequest = {
        agreementNumber: String(agreementObj[0].agreementNumber),
        agreementStatus: '',
        partyId: '',
        leadId: String(
          getPaymentInfoResp.paymentInfo.customerInfo[0].salesLead
        ),
        leadStatus: 'Converted',
        loginName: String(empId),
      };
      SalesLead(salesLeadReq);
    }
    // let AgreementIdList = ReceiptCatogory.filter(x => x.initialPayment === 1);
    // for (let i = 0; i < AgreementIdList.length; i++) {
    //   //let agrId = AgreementIdList[i].agreementId;
    //   //let AgreementcustCall = await AgreementCustomer(agrId);

    // }

    //MakepaymentApiCall();
  };
  const b64toBlob = (
    b64Data: any,
    contentType = 'application/pdf',
    sliceSize = 512
  ) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const ReceiptpdfgenApiCall = async (payload, acceptpaymentRes) => {
    /* eslint-disable no-console */
    console.log('entered into the receipt api call', payload.receiptId);
    let receiptResponse = await Receiptpdfgen(payload);
    console.log('receiptResponse', receiptResponse);
    setload(false);
    if (receiptResponse.status === 200) {
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        if (
          Number(acceptpaymentRes.data?.cellPhoneActivation[i]?.result) > 0 &&
          acceptpaymentRes.data?.cellPhoneActivation[i]?.resultText
            ?.split('+')[0]
            ?.includes('Failed to do the cellphone activation ')
        ) {
          setepayFail(true);
        }
      }
      settran(true);
      if (Agreement > 0) {
        AgreementCustApiCall();
      }
    } else if (
      receiptResponse.status !== 200 &&
      receiptResponse.status !== 403
    ) {
      setTranFail(true);
      setTranFailtxtchange(false);
    } else {
      setTranFail(true);
      setTranFailtxtchange(true);
    }

    /* eslint-disable no-console */

    setreceipturl(receiptResponse);
    setReceiptURL(
      URL.createObjectURL(
        b64toBlob(receiptResponse.data.receiptHtmlUrl, 'text/html')
      )
    );
    if (
      receiptResponse.data.htmlUrl.length > 0 &&
      receiptResponse.data.htmlUrl != undefined
    ) {
      setcofPopup(true);
      for (let i = 0; i < receiptResponse.data.htmlUrl.length; i++) {
        let url = URL.createObjectURL(
          b64toBlob(receiptResponse.data.htmlUrl[i], 'text/html')
        );
        cofArrayUrl.push(url);
      }
      setCOFUrl(cofArrayUrl);
    }
    if (
      receiptResponse.data.extensionHtmlUrl != undefined &&
      receiptResponse.data.extensionHtmlUrl.length > 0
    ) {
      setextPopup(true);
      for (let i = 0; i < receiptResponse.data.extensionHtmlUrl.length; i++) {
        let url = URL.createObjectURL(
          b64toBlob(receiptResponse.data.extensionHtmlUrl[i], 'text/html')
        );
        extensionArrayUrl.push(url);
      }
      console.log(extensionArrayUrl, 'array for ext receipt');
      setExtensionURL(extensionArrayUrl);
    }
    // window.open(receiptResponse.receiptHtmlUrl, '_self');
  };
  const ReceiptpdfgenApiCallCO = async (
    payload,
    acceptpaymentRes,
    epayFail,
    cellPhoneArray,
    resultTextBase64
  ) => {
    /* eslint-disable no-console */
    console.log('entered into the receipt api call', payload);
    const res = await Promise.all(
      payload.map((el: any) =>
        Receiptpdfgen({
          receiptId: Number(el),
          agreementId: finalagr == null ? undefined : finalagr,
          resultText: epayFail == 0 ? resultTextBase64 : '',
          cellPhoneActivation: cellPhoneArray,
          termsArray: acceptpaymentRes.data.termsArray,
        })
      )
    );
    console.log('res of the awaited pdf response', res);
    // let receiptResponse = await Receiptpdfgen(payload);
    // console.log('receiptResponse', receiptResponse);
    setload(false);
    if (res !== undefined) {
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        if (
          Number(acceptpaymentRes.data?.cellPhoneActivation[i]?.result) > 0 &&
          acceptpaymentRes.data?.cellPhoneActivation[i]?.resultText
            ?.split('+')[0]
            ?.includes('Failed to do the cellphone activation ')
        ) {
          setepayFail(true);
        }
      }
      settran(true);
      if (Agreement > 0) {
        AgreementCustApiCall();
      }
    } else if (res == undefined) {
      setTranFail(true);
      setTranFailtxtchange(false);
    } else {
      setTranFail(true);
      setTranFailtxtchange(true);
    }
    let collectedURL: any = [];
    if (res !== undefined) {
      res.map((obj: any, index) => {
        const blobURLGenerated: any = URL.createObjectURL(
          b64toBlob(obj.data.receiptHtmlUrl, 'text/html')
        );
        collectedURL.push(blobURLGenerated);
      });
    }
    setReceiptURLArray(collectedURL);
    setreceipturlCO(res);
    /* eslint-disable no-console */

    // setreceipturl(receiptResponse);
    // setReceiptURL(
    //   URL.createObjectURL(
    //     b64toBlob(receiptResponse.data.receiptHtmlUrl, 'text/html')
    //   )
    // );
    // if (receiptResponse.data.htmlUrl.length > 0 && receiptResponse.data.htmlUrl != undefined) {
    //   setcofPopup(true);
    //   for (let i = 0; i < receiptResponse.data.htmlUrl.length; i++) {
    //     let url = URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.htmlUrl[i], 'text/html')
    //     );
    //     cofArrayUrl.push(url);
    //   }
    //   setCOFUrl(cofArrayUrl)

    // }
    // if (receiptResponse.data.extensionHtmlUrl != undefined && receiptResponse.data.extensionHtmlUrl.length > 0) {
    //   setextPopup(true);
    //   for (let i = 0; i < receiptResponse.data.extensionHtmlUrl.length; i++) {
    //     let url = URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.extensionHtmlUrl[i], 'text/html')
    //     );
    //     extensionArrayUrl.push(url);
    //   }
    //   console.log(extensionArrayUrl, "array for ext receipt")
    //   setExtensionURL(extensionArrayUrl);

    // }
    // window.open(receiptResponse.receiptHtmlUrl, '_self');
  };
  const ActClubApiCall = async (agreementId: number, action: number) => {
    let payObj = getPaymentInfoResp.paymentInfo.paymentInfo.filter(
      (e) => (e.agreementId = agreementId)
    );
    let paymentScheduleId;
    if (payObj[0].paymentSchedule === 'Bi-Weekly') {
      paymentScheduleId = 1;
    } else if (payObj[0].paymentSchedule === 'Weekly') {
      paymentScheduleId = 2;
    } else if (payObj[0].paymentSchedule === 'Monthly') {
      paymentScheduleId = 3;
    } else if (payObj[0].paymentSchedule === 'Semi-Monthly') {
      paymentScheduleId = 4;
    }
    let req = {
      paymentScheduleId: paymentScheduleId,
      customerId: String(customer),
      isCreate: action,
      storeNumber: String(window.sessionStorage.getItem('storeNumber')),
    };
    await ActiveClub(req);
  };
  const AcceptPaymentApiCall = async (acceptApiCall) => {
    let processOrderData: any = undefined;
    if (
      props.isCoPrelim &&
      (props.provideEAFlag == null || props.provideEAFlag == 'No')
    ) {
      processOrderData = {
        eaFlag: 'No',
        agreementNumber: props.provideAgrNumberPO,
      };
    } else if (props.isCoPrelim && props.provideEAFlag == 'Yes') {
      const updatedPlaceOrderAddressData = props.providePOAddressData;
      // updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
      processOrderData = {
        eaFlag: props.provideEAFlag,
        agreementNumber: props.provideAgrNumberPO,
        shippingAddress: updatedPlaceOrderAddressData,
        items: props.providePlaceOrderItemData
      };
    }
    const acceptPaymentReq: AcceptPaymentRequest = {
      raftApiFlag: Number(props.configFlags.raftApiFlag),
      createReceiptReq: acceptApiCall.data.createReceiptReq,
      email: acceptApiCall.data.email,
      phoneNumber: acceptApiCall.data.phoneNumber,
      transactionId: acceptApiCall.data.transactionId,
      miscellaneousArray: acceptApiCall.data.miscellaneousArray,
      placeOrderDetails: processOrderData,
    };

    let acceptpaymentRes: any = await AcceptPaymentApi(acceptPaymentReq);
    if (acceptpaymentRes.status === 200) {
      AcPayment = [];
      ReceiptCatogory = [];
      ClubAcPayment = undefined;
      settxtChange(true);
      if (props.activateClubFeatureFlag == 1) {
        let payload: any = {
          paymentSchedule: null,
          customerId: Number(customer),
          isCreate: 0,
          agreementId: null,
          clubStatusRefCode: 'C',
        };
        const clubFlag = props.ClubPayFlag.filter(
          (x: any) => x.Agreementtype == 'Club'
        );
        if (
          clubBtnStatus == 'P' &&
          clubFlag.length == 0 &&
          props.clubCheckDisabled == true
        ) {
          await ActiveClub(payload);
        }
      }
      window.sessionStorage.setItem('LDWaction', String(''));
      const resultTextBase64 = btoa(acceptpaymentRes.data.resultText);
      let cellPhoneArray: any = [];
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        let cashsale = {
          carrier: acceptpaymentRes.data?.cellPhoneActivation[i].carrier,
          mobileNumber:
            acceptpaymentRes.data?.cellPhoneActivation[i].mobileNumber,
          paymentCharge:
            acceptpaymentRes.data?.cellPhoneActivation[i].paymentCharge,
          pricePlan: acceptpaymentRes.data?.cellPhoneActivation[i].pricePlan,
          productId: acceptpaymentRes.data?.cellPhoneActivation[i].productId,
          productName:
            acceptpaymentRes.data?.cellPhoneActivation[i].productName,
          saleAmount: acceptpaymentRes.data?.cellPhoneActivation[i].saleAmount,
          saleDate: acceptpaymentRes.data?.cellPhoneActivation[i].saleDate,
          simNumber: acceptpaymentRes.data?.cellPhoneActivation[i].simNumber,
          terminalId: acceptpaymentRes.data?.cellPhoneActivation[i].terminalId,
          totalSale: acceptpaymentRes.data?.cellPhoneActivation[i].totalSale,
          transactionId:
            acceptpaymentRes.data?.cellPhoneActivation[i].transactionId,
          serviceFee: acceptpaymentRes.data?.cellPhoneActivation[i].serviceFee,
          consumerDiscount:
            acceptpaymentRes.data?.cellPhoneActivation[i].consumerDiscount,
        };
        cellPhoneArray.push(cashsale);
        // acceptpaymentRes.data?.cellPhoneActivation[i]?.result=undefine
      }
      let epayFail = 0;
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        if (
          Number(acceptpaymentRes.data?.cellPhoneActivation[i]?.result) > 0 &&
          acceptpaymentRes.data?.cellPhoneActivation[i]?.resultText
            ?.split('+')[0]
            ?.includes('Failed to do the cellphone activation ')
        ) {
          epayFail = 1;
        }
      }

      let pdfReq = {};

      if (
        acceptpaymentRes.data.receiptId !== undefined &&
        acceptpaymentRes.data.coReceiptId == undefined
      ) {
        pdfReq = {
          receiptId: Number(acceptpaymentRes.data.receiptId),
          agreementId: finalagr == null ? undefined : finalagr,
          resultText: epayFail == 0 ? resultTextBase64 : '',
          cellPhoneActivation: cellPhoneArray,
          termsArray: acceptpaymentRes.data.termsArray,
        };
        await ReceiptpdfgenApiCall(pdfReq, acceptpaymentRes);
      } else if (acceptpaymentRes.data.coReceiptId !== undefined) {
        await ReceiptpdfgenApiCallCO(
          acceptpaymentRes.data.coReceiptId,
          acceptpaymentRes,
          epayFail,
          cellPhoneArray,
          resultTextBase64
        );
      }

      //await ReceiptpdfgenApiCall(pdfReq,acceptpaymentRes);
    } else {
      setload(false);
      // if (actLDWArr.length > 0) {
      //   for (let i = 0; i < actLDWArr.length; i++) {
      //     await LDwServiceCall(actLDWArr[i]);
      //     if (actLDWArr[i].action == 1) {
      //       ActClubApiCall(actLDWArr[i].agreementId, actLDWArr[i].action);
      //     }
      //   }
      // }
      console.log('epayrescheck', acceptpaymentRes);
      if (
        acceptpaymentRes?.data?.errors[0]?.error
          ?.split('+')[0]
          ?.includes('Failed to do the cellphone activation ')
      ) {
        setepayFail(true);
        setTranFail(true);
      } else {
        setTranFail(true);
      }
    }
  };

  enum CONTENT_TYPE {
    pdf = 'application/pdf',
    html = 'text/html',
    jpg = 'image/jpg',
    jpeg = 'image/jpeg',
    jpe = 'image/jpe',
    gif = 'image/gif',
    png = 'image/png',
    bmp = 'image/bmp',
  }

  const getContentType = (fileName: string) => {
    // if (fileContentType) return fileContentType;
    let result;
    if (fileName && typeof fileName === 'string') {
      const splits = fileName.split('.');
      const extension = splits[splits.length - 1] as string;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      result = CONTENT_TYPE[extension];
    }
    return result || CONTENT_TYPE.pdf;
  };

  const receiptGenerationMultipleCalls = async (receiptId: any) => {
    const resultOfDoct: any = await getDocument(receiptId, 'RCP');
    return resultOfDoct;
  };

  const invokeReceiptSameStore = async (finalResult: any) => {
    setload(true);
    settxtChange(true);
    setFetchReceipt(false);
    let receiptTimeOut = false;
    let resultOfDoct: any;
    setTimeout(async function () {
      receiptTimeOut = true;
    }, 25000);
    while (!receiptTimeOut) {
      resultOfDoct = await receiptGenerationMultipleCalls(
        finalResult.data.receiptInfo[0].receiptId
      );
      console.log('sdsxcew', resultOfDoct, finalResult);

      //  const resultOfDoct: any = await getDocument(finalResult.data.receiptInfo[0].receiptId, "RCP");
      setload(false);

      if (
        resultOfDoct?.status == 200 &&
        Object.keys(resultOfDoct.data)?.length > 0 &&
        resultOfDoct?.data?.fileList[0].fileObject !== null &&
        resultOfDoct?.data?.fileList?.length > 0
      ) {
        console.log('sdx454dd5xc4d', resultOfDoct);
        let collectedhtmlURL: any = [];
        let collectedExtensionHtmlURL: any = [];
        [resultOfDoct].map((el) => {
          let Coorecord = el.data.fileList.filter((x) => x.docType === 'COOS');
          let Receiptrecord = el.data.fileList.filter(
            (x) => x.docType === 'PRCT'
          );
          let Extrecord = el.data.fileList.filter((x) => x.docType === 'EXTRF');

          if (Receiptrecord.length != 0) {
            const type = getContentType(Receiptrecord[0]?.fileName);
            setReceiptURL(
              URL.createObjectURL(b64toBlob(Receiptrecord[0]?.fileObject, type))
            );
          }

          if (Coorecord.length != 0) {
            Coorecord.map((el) => {
              const type = getContentType(el?.fileName);
              const formatedURL = URL.createObjectURL(
                b64toBlob(el?.fileObject, type)
              );
              collectedhtmlURL.push(formatedURL);
              setcofPopup(true);
              cofArrayUrl.push(formatedURL);
              setCOFUrl(cofArrayUrl);
            });
          }

          if (Extrecord.length != 0) {
            Extrecord.map((el) => {
              const type = getContentType(el?.fileName);
              const formatedURL = URL.createObjectURL(
                b64toBlob(el?.fileObject, type)
              );
              collectedExtensionHtmlURL.push(formatedURL);
              setextPopup(true);
              extensionArrayUrl.push(formatedURL);
              setExtensionURL(extensionArrayUrl);
            });
          }
        });
        setreceipturl({
          data: {
            htmlUrl: collectedhtmlURL,
            extensionHtmlUrl: collectedExtensionHtmlURL,
          },
          status: 200,
        });
        receiptTimeOut = true;
        settxtChange(false);
        break;
      }
    }
    if (
      receiptTimeOut &&
      !(
        resultOfDoct?.status == 200 &&
        Object.keys(resultOfDoct)?.length > 0 &&
        resultOfDoct?.fileList?.length > 0
      )
    ) {
      setFetchReceipt(true);
    }
  };

  const invokeReceiptParallel = async (finalResult: any) => {
    let isFailed: any = [];
    let isPassed: any = [];
    let totalFileList: any = [];
    await Promise.all(
      finalResult.map(async (el) => {
        if (!el.isPaymentFailed) {
          const resultOfDoct: any = await getDocument(el.receiptID, 'RCP');
          console.log('sdcvsdfcewfsd', resultOfDoct);
          resultOfDoct.data.storeNumber = el.storeNumber;
          if (
            resultOfDoct.status == 200 &&
            Object.keys(resultOfDoct.data).length > 1 &&
            resultOfDoct.data.fileList[0].fileObject !== null
          ) {
            isPassed.push(resultOfDoct);
          } else {
            isFailed.push(resultOfDoct);
          }
        } else {
          totalFileList.push({
            documentURL: '',
            storeNumber: el.storeNumber,
            isPaymentFailed: true,
          });
        }
      })
    );
    console.log('uycfhvkgftdfxhcj', isPassed, isFailed);

    if (isFailed.length && receptFailedAttempts < 10) {
      receptFailedAttempts = receptFailedAttempts + 1;
      invokeReceiptParallel(finalResult);
    } else {
      const combinedData = [...isPassed, ...isFailed];
      combinedData.map((el) => {
        console.log('wsedfgvdsel', el);

        if (
          el.status == 200 &&
          Object.keys(el.data).length > 1 &&
          el.data.fileList[0].fileObject !== null
        ) {
          let Coorecord = el.data.fileList.filter((x) => x.docType === 'COOS');
          let Receiptrecord = el.data.fileList.filter(
            (x) => x.docType === 'PRCT'
          );
          let Extrecord = el.data.fileList.filter((x) => x.docType === 'EXTRF');
          if (el.data.fileList.length != 0) {
            if (Receiptrecord.length != 0) {
              const type = getContentType(Receiptrecord[0]?.fileName);
              const formatedURL = URL.createObjectURL(
                b64toBlob(Receiptrecord[0]?.fileObject, type)
              );
              totalFileList.push({
                documentType: 'PRCT',
                documentURL: formatedURL,
                storeNumber: el.data.storeNumber,
                isPaymentFailed: false,
                isLoading: false,
              });
            }
            if (Coorecord.length != 0) {
              const type = getContentType(Coorecord[0]?.fileName);
              const formatedURL = URL.createObjectURL(
                b64toBlob(Coorecord[0]?.fileObject, type)
              );
              totalFileList.push({
                documentType: 'COOS',
                documentURL: formatedURL,
                storeNumber: el.data.storeNumber,
                isPaymentFailed: false,
                isLoading: false,
              });
            }
            if (Extrecord.length != 0) {
              const type = getContentType(Extrecord[0]?.fileName);
              const formatedURL = URL.createObjectURL(
                b64toBlob(Extrecord[0]?.fileObject, type)
              );
              totalFileList.push({
                documentType: 'EXTRF',
                documentURL: formatedURL,
                storeNumber: el.data.storeNumber,
                isPaymentFailed: false,
                isLoading: false,
              });
            }
          }
        } else {
          totalFileList.push({
            documentType: '',
            documentURL: '',
            storeNumber: el.data.storeNumber,
            isPaymentFailed: false,
            isLoading: false,
          });
        }
      });
      receptFailedAttempts = 0;
    }

    setreceiptResultArray(totalFileList);

    console.log('wswdef3ef444rf', totalFileList);

    console.log('res value in parallel recept call', isFailed, isPassed);
  };

  const processPayment = async () => {
    const processPaymentPayload = {
      raftApiFlag: '0',
      paymentInfo: [
        {
          FirstName: 'John',
          LastName: 'Cena',
          storeMerchantId: '1234',
          placeOrderDetails: {
            eaFlag: '1',
            agreementNumber: '1234',
          },
          storeNumber: '04741',
          customerId: '108765432',
          paymentOrigin: 'Store',
          deactivateClub: 'N',
          agreementPayment: [
            {
              agreementId: '1',
              editedSchedule: 'Monthly',
              activateLdw: 'Y',
              adjustedDueDate: '01/01/1999',
              isZeroPayment: 'N',
              freeTime: {
                type: 'Regular',
                freeDays: '2',
                waiveOptionalService: 'N',
              },
              carryLateFeeAmount: '2.50',
              waiveLateFeeAmount: '5.00',
              epoPayment: 'Y',
              epoDiscount: {
                epoDiscount: '200',
                pastDueDiscount: '200',
                noteDiscount: '200',
              },
            },
          ],
          clubPayment: [
            {
              customerClubId: '1087654321',
              isZeroPayment: 'N',
              editedSchedule: 'Monthly',
              adjustedDueDate: '01/01/1999',
            },
          ],
          transferSuspense: [
            {
              agreementId: '891456782',
              balance: '20.00',
              isTransferToCoa: 'N',
            },
          ],
          miscPayment: [
            {
              type: 'CashSale',
              amount: '50.00',
              tax: '20.00',
              itemData: {},
            },
          ],
          paymentMethod: {
            tenderDetails: [
              {
                type: 'Cash',
                amount: '50.00',
                tenderNumber: '12345',
                checkType: 'Check',
                checkWrittenBy: 'John',
                checkWrittenTo: 'Arena',
                purchaser: 'RAC',
                issuer: 'RAC',
              },
            ],
            changeAmount: '5.00',
            cardDetails: [
              {
                amount: '123.00',
                authNumber: 'eHgYUGSIubdogo',
                cardLastFour: '1111',
                paymentToken: '156171617',
                cardType: 'cc',
                cardTransactionType: 'paytok',
                externalTransactionId: '123456789',
                expirationDate: '03/20',
                billingAddress: {
                  city: 'Jersy',
                  zip: '098765',
                  state: 'AZ',
                  addressLine1: '102Ab',
                  addressLine2: '103Ab',
                },
              },
            ],
            customerAccount: [
              {
                accountType: 'COAADD',
                storeNumber: '04741',
                amount: '20.00',
                agreementId: '1234567',
              },
            ],
          },
        },
      ],
    };

    const finalPayload: any = [];

    props.selectedAgreements.map((el) => {
      if (props.selectedAgreements.length <= 3) {
        processPaymentPayload.paymentInfo[0].agreementPayment[0].agreementId =
          props.selectedAgreements.length;
      }
      finalPayload.push(processPaymentPayload);
    });
    let finalResult: any = [];
    await Promise.all(
      finalPayload.map(async (el) => {
        const resultOfDoct: any = await ProcessPaymentAPI(el);
        console.log('sdcvsdfcewfsd', resultOfDoct);
        if (resultOfDoct.status == 200) {
          finalResult.push({
            storeNumber: el.paymentInfo[0].storeNumber,
            receiptID: resultOfDoct.data.receiptInfo[0].receiptId,
            isPaymentFailed: false,
          });
        } else {
          finalResult.push({
            storeNumber: el.paymentInfo[0].storeNumber,
            receiptID: '',
            isPaymentFailed: true,
          });
        }
      })
    );
    console.log('finalResult', finalResult);
    let paymentAPIResult: any = [];
    let receiptConditionData: any = [];
    finalResult.map((el) => {
      paymentAPIResult.push({
        storeNumber: el.storeNumber,
        transactionStatus: el.isPaymentFailed ? 'Failed' : 'Passed',
      });
      receiptConditionData.push({
        documentType: '',
        documentURL: '',
        storeNumber: el.storeNumber,
        isPaymentFailed: el.isPaymentFailed,
        isLoading: true,
      });
    });
    settransactionResultArray(paymentAPIResult);
    invokeReceiptParallel(finalResult);
    setreceiptResultArray(receiptConditionData);
    setload(false);
    setShowPaymentStatus(true);
  };

  const OwnershipTray = () => {
    const getUrl = window.location.href;
    if (cofUrlState !== undefined && cofUrlState.cofurl !== '') {
      setTray1(true);
      settran(false);
    }
    if (
      receipturlCO !== undefined &&
      receipturlCO[0].status === 200 &&
      (getUrl.includes('/payment1/customerorder/preliminary/') ||
        getUrl.includes('/payment1/customerorder/initial/'))
    ) {
      setrecptCO(true);
    } else if (receipturl.status === 200) {
      console.log('entered into the redirection condition');
      setrecpt(true);
    }
    setepayFail(false);
    settran(false);
  };

  /**RIS_PC_NO_44 showPopup() function is triggered when Accept payment button is clicked and inside the
   * function condition check is done
   */
  const showPopup = (validateForFT) => {
    // debugger;
    console.log('check the props.totalsuspensemaster logger', props.totalsuspensemaster);
    console.log('check the props.selectedAgreements logger', props.selectedAgreements);
    let storeNumber=window.sessionStorage.getItem('storeNumber')

    console.log('Selected Agreements', props.selectedAgreements);
    console.log('Pending Agreements in Process Payment', props?.pendingAgreements);
    console.log('exchangeAgreementNumberMapper PP', props?.exchangeAgreementNumberMapper);
    console.log('parentAgreementIdMapper in Process Payment', props?.parentAgreementIdMapper);
    console.log('autoPayEnrolledAgreement in PP', props?.autoPayEnrolledAgreement);
    console.log('isExchangeAgreementIndicator in PP', props?.isExchangeAgreementIndicator);

    const currentPendingAgreements = props?.selectedAgreements?.filter((element: any) => element?.agreementStatus == 'PEND');

    const pendingAgreements: any = []; 
    
    currentPendingAgreements.forEach((element : any) => {
      pendingAgreements.push(element?.Agreementid)
    });
    const autoPayEnrolledAgreementNumbersMapper : any = [];
    pendingAgreements.forEach((element : any) => {
      console.log(props?.parentAgreementIdMapper[element], props?.isExchangeAgreementIndicator[props?.parentAgreementIdMapper[element]], props?.autoPayEnrolledAgreement.includes(props?.parentAgreementIdMapper[element]), 'AutoPay enroll logger check');
      if(props?.parentAgreementIdMapper[element] && props?.isExchangeAgreementIndicator[props?.parentAgreementIdMapper[element]] && props?.autoPayEnrolledAgreement.includes((props?.parentAgreementIdMapper[element]).toString())){
        autoPayEnrolledAgreementNumbersMapper.push({
          agreementId : props?.parentAgreementIdMapper[element],
          agreementNumber : props?.exchangeAgreementNumberMapper[props?.parentAgreementIdMapper[element]]
        })
      }
    });

    console.log('autoPayEnrolledAgreementNumbersMapper', autoPayEnrolledAgreementNumbersMapper);

    if(autoPayEnrolledAgreementNumbersMapper?.length){
      // props?.setIsAutoPayEnabledAgreement(true);
      props?.setExchangeAgreementNumber(autoPayEnrolledAgreementNumbersMapper);
    }

    console.log('Pending Agreement AFT', pendingAgreements);

    if (validateForFT) {
      const validationResponse = cappedFreeTimeValidation(props);
      const pendingAgreements = props?.selectedAgreements.filter((agr) => agr.agreementStatus == 'PEND')
      let totalExtensionAmount = 0;
      let pastDayCreatedAgreements: any = [];
      pendingAgreements.forEach((element: any) => {
        totalExtensionAmount += element.extensionAmount;
        moment(element.openDate).isBefore(moment(), 'day') ? pastDayCreatedAgreements.push(element) : null;
      });
      if (validationResponse?.requiredValidation?.includes('INITPAY') && totalExtensionAmount > validationResponse?.remainingAmount && pastDayCreatedAgreements?.length > 0) {
        props.setExceededFreeTime(props?.exceededFTAmount + (validationResponse.remainingAmount < 0 ? totalExtensionAmount : validationResponse.remainingAmount - totalExtensionAmount));
        setFreeTimeAuthPopup(true);
      }
      else {
        // setCappedFreeTimeAuthCompleted(false);
        showPopup(false);
      }
    }
    else {
      if (storeNumber != "" && storeNumber != "null" && storeNumber != null && storeNumber != "undefined" && storeNumber != undefined) {
        if (props.totalsuspensemaster && props.totalsuspensemaster.length > 0) {
      const filterUsedSuspense = props.totalsuspensemaster.filter((el) => el.sustype == 'Used');
      const sumOfSuspenseUse = filterUsedSuspense.reduce((total, obj) => total + Number(obj.newsuspense || 0), 0);
      const sumOfTotal = props.selectedAgreements.reduce((total, obj) => total + Number(obj.Amount || 0), 0);
      console.log('sum in lthe logegw', sumOfSuspenseUse, props.selectedAgreements);
      console.log('sum in lthe sumOfTotal', sumOfTotal);
      if (sumOfSuspenseUse > sumOfTotal) {
        setOpenSuspenseWithdraw(true);
      } else {
        setTwoFactorCompleted(false);
        setTwoFactorCancelClick(false);
        setSecondFactorOpen(true);
      }
    } else {
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(false);
      setSecondFactorOpen(true);
    }
  }
      else {
        setSessionExpiredPopupFlag(true)
      }
    }
  };

  async function unEnrollAutoPay() {
    let agreementArray : any = [];
    try{
      if(props?.exchangeAgreementNumber.length){
        props?.exchangeAgreementNumber?.forEach((element) => {
          agreementArray.push(element?.agreementId?.toString());
        })
        UnEnrollAutoPay({
          autopayFlag : 0,
          agreementIds : agreementArray
        });
      }
    }
    catch(e){
      console.log(e, 'Error in Catch');
      //catch handle
    }
  }

  const freeTimeAuthPopupContent = () => {
    return(
      <Grid className={`${classes2.textCenter}`}>
        <AlertIcon/>
        <Typography className={`${classesTwo.spacerMT3} ${classesTwo.customerRacpopup1}`}>
          Allocated free time for this store has been exceeded. Do you still want to proceed ? 
        </Typography>
        <Grid className={`${classesTwo.buttonsPadding}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classesTwo.customerFloatleft}`}
            onClick={() => {
              setFreeTimeAuthPopup(false);
              setProceedButtonLoader(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={`${classesTwo.customerFloatright}`}
            onClick={() => {
              setProceedButtonLoader(true);
              setSecondFactorAuth(true);
            }}
            loading={proceedButtonLoader} 
          >
            Proceed
          </RACButton>
        </Grid>
      </Grid>
    )
  }

  const openAutoPayPopup = () => {//RAC2
    return(
      <Grid className={`${classes2.textCenter}`}>
        <Typography className={`${classesTwo.spacerMT3} ${classesTwo.customerRacpopup2}`}>
          Autopay will be un-enrolled for the Exchanged agreement {props?.exchangeAgreementNumber ? props?.exchangeAgreementNumber?.map((element: any) => element?.agreementNumber)?.join(', ')?.length > 25 ? props?.exchangeAgreementNumber?.map((element: any) => element?.agreementNumber)?.join(', ')?.slice(0, 20) + '...' : props?.exchangeAgreementNumber?.map((element: any) => element?.agreementNumber)?.join(', ') : exchangeAgreementId}.
        </Typography>
        <Typography className={`${classesTwo.mt11} ${classesTwo.customerRacpopup3}`}>
          Does the customer want to enroll in autopay for the New Agreement?
        </Typography>
        <Grid className={`${classesTwo.buttonsPadding} ${classesTwo.centerButton}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classesTwo.customerFloatleft} ${classesTwo.mr15px}`}
            onClick={() => {
              // unEnrollAutoPay(Agreement);
              // UnEnrollAutoPay({
              //   autopayFlag : 0,
              //   agreementIds : [exchangeAgreementId.toString()],
              // });
              setAutoPayConfirmationPopup(false);
              history.push(`/agreement/rental/delivery/${customer}/${Agreement}?exagreementid=${exchangeAgreementId}`)
            }}
          >
            No
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={`${classesTwo.customerFloatright}`}
            onClick={() => {
              // UnEnrollAutoPay({
              //   autopayFlag: 0,
              //   agreementIds: [exchangeAgreementId.toString()],
              // });
              props?.showAutoPayPopup()
            }}
            loading={proceedButtonLoader} 
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    )
  }

  const transCompleteOk = () => {
    const getUrl = window.location.href;
    if (getUrl.includes('/customerorder/')) {
      setrecptCO(true);
    } else {
      setrecpt(true);
    }
    settran(false);
};

  const sessionReload =()=>{
    let storeNumber=window.sessionStorage.getItem("storeNumber")
    if(storeNumber == null || storeNumber == "null" || storeNumber == "undefined" ||storeNumber == undefined){
      window.sessionStorage.setItem("storeNumber","");
      window.location.reload();
    }
    else{
    window.location.reload();
    }
  };

  const ReceiptOk = () => {
    // debugger;
    setrecpt(false);
    setFetchReceipt(false);
    if (props.activateClubFeatureFlag == 1) {
      const clubInformation = props.paygridvalues.filter(
        (x: any) => x.Agreementtype == 'Club'
      );
      const installmentInfo = props.ClubPayFlag.filter(
        (x: any) =>
          (x.Agreementtype == 'PIF Rent' ||
            (x.Agreementtype == 'EPO Rent' && x.acceptEpoFlag == 0 && !x.isFinalTransferSusUseApplied) ||
            (x.dueDateModified && x.Agreementtype == 'EPO Rent' && x.acceptEpoFlag == 0 && !x.isFinalTransferSusUseApplied)||
            (x.editSchedule && x.Agreementtype == 'EPO Rent' && x.acceptEpoFlag == 0 && !x.isFinalTransferSusUseApplied)) &&
            x.agreementNoteDetails.isNoteAgreement == 1
           
      );
      const clubFlag = props.ClubPayFlag.filter(
        (x: any) => x.Agreementtype == 'Club'
      );
      if (
        clubInformation[0] == undefined
          ? false
          : (clubInformation[0].clubDocumentSignStatus == null ||
            clubInformation[0].clubDocumentSignStatus == 'UK') &&
          clubInformation[0].originAgreementId == null &&
          clubFlag.length > 0 &&
          clubInformation[0]?.number_of_paymentsmade == 0
      ) {
        customerDocGen();
        setcustomerSignaturePopup(true);
        setrecpt(false);
      } else if (installmentInfo?.length > 0) {
        installmentAgrSignFlow(installmentInfo);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        let returnAGRParamValue: any;
        let CPSAGRParamValue: any;
        const returnType = urlParams.get('type');
        if (returnType == 'RETURN' || returnType == 'CHRGOFF') {
          returnAGRParamValue = urlParams.get('agreementId');
          CPSAGRParamValue = urlParams.get('cps');
        }
        if (Number(returnAGRParamValue) > 0) {
          history.push({
            pathname: `/agreement/info/details/${customer}/${returnAGRParamValue}`,
            search: `?type=${returnType}`,
          });
        } else {
          redirectionFunc();
        }
      }
    } else {
      redirectionFunc();
    }
  };
  const customerDocGenInstallmet = async (id: any) => {
    const generateDocPayload = {
      identifier: Number(id),
      identifierType: 'AGR',
      signingUrlRequired: false,
      signers: [
        {
          customerId: String(customer),
          customerName: String(
            props?.customerData?.data?.GetCustomer?.value.firstName +
            ' ' +
            props?.customerData?.data?.GetCustomer?.value.lastName
          ),
          phoneNumber: String(
            props?.customerData?.data?.GetCustomer?.value?.phones[0]
              ?.phoneNumber
          ),
          emailId: String(
            props?.customerData?.data?.GetCustomer?.value?.emailAddress
          ),
          priority: 1,
        },
      ],
      signatureType: 1,
      isRemote: false,
      manualSignatureReasonId: null,
      documentType: ['INOTE'],
      returnURL: window.location.origin,
    };
    await GenerateAgreementDoc(generateDocPayload);
    setclubSignFlow(true);
  };
  const installmentAgrSignFlow = (installmentInfo) => {
    // debugger
    for (let i = 0; i < installmentInfo?.length; i++) {
      customerDocGenInstallmet(Number(installmentInfo[i].Agreementid));
      setPifAgreementID(Number(installmentInfo[i].Agreementid));
      setcustomerSignaturePopupInote(true);
      setrecpt(false);
    }
  };
  const redirectionFunc = () => {
    setShowReceiptStatus(false);
    const getUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const retentionType = urlParams.get('CRTYPE');
    const retentionSource = urlParams.get('SRC');
    if (Agreement > 0 && getUrl.includes('/customerorder/initial/')) {
      history.push({
        pathname: `/agreement/info/details/${customer}/${Agreement}`,
      });
    } else if (
      Agreement > 0 &&
      getUrl.includes('/customerorder/preliminary/')
    ) {
      history.push({
        pathname: `/agreement/customerorder/postatus/${customer}/${Agreement}`,
      });
    } else if (Agreement > 0) {
      history.push({
        pathname: `/agreement/rental/delivery/${customer}/${Agreement}`,
      });
    } else if (location?.state?.redirectSource === 'am'|| window.name=='am') {
      setRedirectToAccountManagement(true);
    } else if (retentionType && retentionSource == undefined) {
      setRedirectToAccountManagement(true);
    } else if (retentionType && retentionSource == 'PAYMENT') {
      history.push({
        pathname: `/payment1/paymentinformation/${customer}/${Agreement}`,
      });
      window.location.reload();
    } else {
      window.location.reload();
    }
  };
  // useEffect(() => {
  //   customerDocGen()
  // },[clubdocumentgenerate==true])

  const customerDocGen = async () => {
    const generateDocPayload = {
      identifier: Number(customer),
      identifierType: 'CUST',
      signingUrlRequired: false,
      signers: [
        {
          customerId: String(customer),
          customerName: String(
            props?.customerData?.data?.GetCustomer?.value.firstName +
            ' ' +
            props?.customerData?.data?.GetCustomer?.value.lastName
          ),
          phoneNumber: String(
            props?.customerData?.data?.GetCustomer?.value?.phones[0]
              ?.phoneNumber
          ),
          emailId: String(
            props?.customerData?.data?.GetCustomer?.value?.emailAddress
          ),
          priority: 1,
        },
      ],
      signatureType: 1,
      isRemote: false,
      manualSignatureReasonId: null,
      documentType: ['CLUB'],
      returnURL: window.location.origin,
    };
    await GenerateAgreementDoc(generateDocPayload);
    setclubSignFlow(true);
  };

  const receiptId = () => {
    window.open(receipturl.data.receiptHtmlUrl);
  };

  const cof = (index: number) => {
    window.open(receipturl.data.htmlUrl[index]);
  };

  const extensionUrl = (index: number) => {
    window.open(receipturl.data.extensionHtmlUrl[index]);
  };

  const receipt = () => {
    setOwnership(false);
    setrecpt(true);
    //settran(false);
  };

  const OwnerShipPopup = () => {
    setOwnership(true);
    setTray1(false);
  };

  const close = () => {
    settran(false);
    setOwnership(false);
    setTray1(false);
    setrecpt(false);
    setrecptCO(false);
  };

  const print = () => {
    eventFrame.current?.contentWindow?.print();
  };

  const ownershipurl = () => {
    window.open(
      'https://generatepdfinracpad.s3.us-east-2.amazonaws.com/Mano%20RanjithCertificateOfOwnership.pdf'
    );
  };

  const spinnerpopup = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={load} size={40} />
          <Typography className="row justify-content-center popup-text">
            Transaction In Progress
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const receiptPopUp = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={
              props.isOtherStoreAgrSelected
                ? `${receiptResultArray[choosenReceiptIndex]?.documentURL}#toolbar=0`
                : `${ReceiptURL}#toolbar=0`
            }
          // src={`${ReceiptURL}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewReceiptPopup(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const buildSuspenseWithdraw = () => {
    return (
      <Grid
        item
        className={classes.paymentmodal}
      >
        <Grid item md={12} className={classes.textcenter}>
          <Typography className="row justify-content-center popup-text">
            Customer is withdrawing from Suspense / Waiting for Signature
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Grid className={classes.mt3}>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.me2}
              onClick={() => {
                setOpenSuspenseWithdraw(false);
                setTwoFactorCompleted(false);
                setTwoFactorCancelClick(false);
                setSecondFactorOpen(true);
              }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const sessionExpiredReloadPopup = () => {
    return (
      <div>
        <Grid className={classes.textcenter} style={{marginBottom:"22px"}}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography className={classes.racErrorSessionFunction}>
              Your session has expired
            </Typography>
            <Typography className={classes.popUpTxtStyleSession}>
              Please login again
            </Typography>
        </Grid>
        <Grid className={classes.textcenter}
        style={{marginLeft:"3px"}}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => sessionReload()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const receiptPopUpCO = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${ReceiptURLArray[selectedIndex]?.blobData}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewReceiptPopupCO(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const transactioncompletedPopup = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <SuccessIcon></SuccessIcon>
          <Typography
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Transaction completed successfully
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => transCompleteOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const viewReceipt = (index?) => {
    setviewReceiptPopup(true);
    choosenReceiptIndex = index;
  };
  const viewReceiptCO = (index: any) => {
    setviewReceiptPopupCO(true);
    setSelectedIndex(index);
  };
  const openExtReceipt = (index: number) => {
    setextIndex(index);
    setviewExtPopup(true);
  };
  const openCOFReceipt = (index: number) => {
    setcofIndex(index);
    setviewCOFPopup(true);
  };
  const viewCOF = (index: number) => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${COFUrl[index]}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewCOFPopup(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const viewExtReceipt = (index: number) => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${ExtensionURL[index]}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewExtPopup(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const cofReceipt = () => {
    return receipturl?.data?.htmlUrl?.map((value: any, index: any) => {
      return (
        <>
          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Certificate of Ownership :
            <a
              onClick={() => openCOFReceipt(index)}
              style={{
                textDecoration: 'underline',
                color: '#2179FE',
                cursor: 'pointer',
              }}
              className={`${classes.mt3}${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
        </>
      );
    });
  };
  const extensionReceipt = () => {
    return receipturl?.data?.extensionHtmlUrl?.map((value: any, index: any) => {
      return (
        <>
          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Extension Receipt Form:{' '}
            <a
              onClick={() => openExtReceipt(index)}
              style={{
                textDecoration: 'underline',
                color: '#2179FE',
                cursor: 'pointer',
              }}
              className={`${classes.mt3}${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
        </>
      );
    });
  };

  const Receiptpopup = () => {
    return (
      <Grid
        item
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
        data-testid="receiptIdBtn"
      >
        <Grid item md={12} className={`${classes.py4} ${classes.textcenter}`}>
          {/* apply racstrap here */}

          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Payment Receipt - {receiptID} :
            {txtChange && !fetchReceipt ? (
              <CircularProgress className={classes.receiptLoader} />
            ) : !txtChange && fetchReceipt ? (
              <a
                onClick={() => viewReceipt()}
                style={{
                  textDecoration: 'underline',
                  color: '#2179FE',
                  cursor: 'pointer',
                }}
                className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
              >
                View
              </a>
            ) : (
              'Generating receipt failed Please contact IT Help Desk'
            )}
          </Typography>
          {cofPopup ? (
            <>
              <Typography
                className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
              >
                Please load the Certificate of Ownership into Tray1 with face up
                and the top of the form facing front.
              </Typography>
              {cofReceipt()}
            </>
          ) : null}
          {extPopup ? extensionReceipt() : null}
          <Grid className={`${classes.w100} ${classes.textcenter}`}>
          {Agreement > 0 && exchangeAgreementId ? 
            <RACButton 
                className={classes.mx1}
                color="primary"
                variant="outlined"
                data-testid="ReceiptCancel"
                style={{ marginTop: '20px' }}
                onClick={() => {
                  postAgreementActivity({agreementActivities : [{
                    agreementId: Agreement,
                    activityDescription: `Agreement Exchange Started`,
                    agreementActivityType: "AGMXNGSRT",
                  }]});
                  if(props?.isAutoPayEnabledAgreement){
                    setAutoPayConfirmationPopup(true);
                  }
                  else{
                    props?.showAutoPayPopup()
                  }
                }}
            >
              OK
            </RACButton>
            :<RACButton
              className={classes.mx1}
              color="primary"
              variant="outlined"
              onClick={() => {
                if (
                  props.throwtakeCommitmentData
                ) {
                  setrecpt(false);
                  setopenCommitmentConfirmation(true);
                } else {
                  ReceiptOk();
                }
              }}
              data-testid="ReceiptCancel"
              style={{ marginTop: '20px' }}
            >
              OK
            </RACButton>}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ReceiptpopupCO = () => {
    return (
      <Grid
        item
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
        data-testid="receiptIdBtn"
      >
        <Grid item md={12} className={`${classes.py4} ${classes.textcenter}`}>
          {/* apply racstrap here */}
          {ReceiptURLArray?.map((obj, index) => {
            return (
              <Typography
                key={index}
                className={`${classes.colmd12} ${classes.mb2}`}
              >
                Payment Receipt {ReceiptURLArray[index].receiptId} :
                {ReceiptURLArray[index]?.blobData == '' && !fetchReceipt || ReceiptURLArray[index]?.blobData != '' && !fetchReceipt ? (
                  <CircularProgress className={classes.receiptLoader} />
                ) : ReceiptURLArray[index]?.blobData != '' && fetchReceipt ? (
                  <a
                    onClick={() => viewReceiptCO(index)}
                    style={{
                      textDecoration: 'underline',
                      color: '#2179FE',
                      cursor: 'pointer',
                    }}
                    className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                  >
                    View
                  </a>
                ) : (
                  'Generating receipt failed Please contact IT Help Desk'
                )}
              </Typography>
            );
          })}
          {cofPopup ? (
            <>
              <Typography
                className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
              >
                Please load the Certificate of Ownership into Tray1 with face up
                and the top of the form facing front.
              </Typography>
              {cofReceipt()}
            </>
          ) : null}
          {extPopup ? extensionReceipt() : null}
          <Grid className={`${classes.w100} ${classes.textcenter}`}>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="outlined"
              onClick={() => ReceiptOk()}
              data-testid="ReceiptCancel"
              style={{ marginTop: '20px' }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Tray1Popup = () => {
    return (
      <Grid
        item
        data-testid="ownershidPopupTestId"
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
      >
        <Typography
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
        >
          please load the certificate of ownership into Tray1 with face up and
          the top of the form facing front
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-testid="certificateId"
            onClick={() => OwnerShipPopup()}
            data-bs-target="#delivery-receipt"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const okClickUnavailable = () => {
    setIsAmountMismatch(false);
    setTranFail(false);
    setepayFail(false);
    window.location.reload();
  };

  const unAvailable = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {epayFail == false && cardPaymentFailedMessage=='' ? (
            <Typography className={classes.racErrorFunction}>
              Transaction has been failed
            </Typography>
          ) :epayFail == true? (
            <Typography className={classes.racErrorFunction}>
              Failed to do the cellphone activation please reach out IT service
              desk
            </Typography>
          ):cardPaymentFailedMessage!==''?
            <Typography className={classes.racErrorFunction}>
              {cardPaymentFailedMessage}
            </Typography>:null}

        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={okClickUnavailable}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const renderAmountMismatch = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            There was an error processing your request. Please use ‘Report an Issue’ quick link under help, or Self Service portal to report this issue, and someone from the support team will promptly assist you with this request.
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={okClickUnavailable}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const fetchReceiptPopUp = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {epayFail == false ? (
            <Typography className={classes.racErrorFunction}>
              Failed to get the receipts
            </Typography>
          ) : (
            <Typography className={classes.racErrorFunction}>
              Failed to do the cellphone activation please reach out IT service
              desk
            </Typography>
          )}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => {
              const getUrl = window.location.href;
              if (getUrl.includes('/customerorder/')) {
                invokeCOReceipt(processPaymentInfo);
              } else {
                invokeReceiptSameStore(processPaymentInfo);
              }
            }}
          >
            Try Again
          </RACButton>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => ReceiptOk()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const epayunAvailable = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {epayFail == true ? (
            <Typography className={classes.racErrorFunction}>
              Failed to do the cellphone activation please reach out IT service
              desk
            </Typography>
          ) : null}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => OwnershipTray()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const transactionResultHeader = () => (
    <>
      <RACTableCell style={{ textAlign: 'center' }}>Store Number</RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>
        Transaction Status
      </RACTableCell>
    </>
  );

  const transactionResultBody = () => (
    <>
      {console.log('sdfgfdfe23wefd', transactionResultArray)}
      {transactionResultArray?.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.storeNumber}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.transactionStatus == 'Passed' ? (
                <TransactionSuccessIcon className={classes.receiptLoader} />
              ) : (
                <FailIcon />
              )}
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const buildPaymentStatus = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <RACTable
            className={`${classes.racGrid} ${classes.mb32}`}
            renderTableHead={transactionResultHeader}
            renderTableContent={transactionResultBody}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setShowPaymentStatus(false);
              setShowReceiptStatus(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const receiptResultHeader = () => (
    <>
      <RACTableCell style={{ textAlign: 'center' }}>Store Number</RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>Receipt Type</RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>
        Receipt Status
      </RACTableCell>
    </>
  );

  const receiptResultBody = () => (
    <>
      {receiptResultArray?.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.storeNumber}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.documentType == 'PRCT'
                ? 'Payment Receipt'
                : obj.documentType == 'COOS'
                  ? 'Certificate of Ownership'
                  : obj.documentType == 'EXTRF'
                    ? 'Extension Receipt Form'
                    : '-'}
            </RACTableCell>
            {obj.isLoading ? (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.receiptLoader} />
              </RACTableCell>
            ) : obj.isPaymentFailed ? (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <FailIcon />
              </RACTableCell>
            ) : obj.documentURL !== '' ? (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <a
                  onClick={() => viewReceipt(index)}
                  style={{
                    textDecoration: 'underline',
                    color: '#2179FE',
                    cursor: 'pointer',
                  }}
                  className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                >
                  View
                </a>
              </RACTableCell>
            ) : (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <a
                  onClick={() => {
                    setreceiptResultArray(initialReceiptStatus);
                    setShowReceiptStatus(true);
                    invokeReceiptParallel(finalPaymentResult);
                  }}
                  style={{
                    textDecoration: 'underline',
                    color: '#2179FE',
                    cursor: 'pointer',
                  }}
                  className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                >
                  Retry
                </a>
              </RACTableCell>
            )}
            {/* <RACTableCell key={index} style={{ textAlign: "center" }}>
              {obj.isLoading ? (
                <CircularProgress className={classes.receiptLoader}/>
              ) : obj.isPaymentFailed ? (
                <FailIcon />
              ) : obj.documentURL !== "" ? (
                "View"
              ) : (
                "Retry"
              )}
            </RACTableCell> */}
          </RACTableRow>
        );
      })}
    </>
  );

  const buildReceiptStatus = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <RACTable
            className={`${classes.racGrid} ${classes.mb32}`}
            renderTableHead={receiptResultHeader}
            renderTableContent={receiptResultBody}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              // setShowReceiptStatus(false);
              ReceiptOk();
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const commitmentConfirmation = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            Do you want to take recommitment ?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={ReceiptOk}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/am/customer/${customerId}`,
                search: `?type=RECOMMIT`,
              });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  {
    /**Rental Item Sale starts here */
  }
  /**RIS_PC_NO_46 The changepopup() is rendered when changepopupFlag is updated as true */
  const changepopup = () => {
    return (
      <div>
        <Grid className={classes2.textcenter}>
          {/* <AlertIcon ></AlertIcon> */}
          <Typography className={classes.racErrorFunction}>
            The transaction was saved successfully. Change owed to customer
            {props.Paymentchange}
          </Typography>
        </Grid>
        <Grid className={classes2.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2N}
            onClick={() => {
              btnOk();
            }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  /**RIS_PC_NO_46 btnOk() function is triggered when co-worker clicks the ok button
   * in changepopup()
   */
  const btnOk = () => {
    setload(true);
    // setSecondFactorOpen(false);
    setChangepopupFlag(false);
    extensionArrayUrl = [];
    cofArrayUrl = [];
    MakepaymentApiCall();
  };
  /**RIS_PC_NO-46 The customerPresentpopup() is rendered when customerPresentpopupFlag is updated as true */
  const customerPresentpopup = () => {
    return (
      <div>
        <Grid className={classes2.textcenter}>
          {/* <AlertIcon ></AlertIcon> */}
          <Typography variant="h4" className={classes.racErrorFunction}>
            Is customer present?
          </Typography>
        </Grid>
        <Grid container style={{ marginLeft: '10px', marginTop: '40px' }}>
          <Grid item md={4} className={classes.floatLeft}>
            <RACButton
              style={{ marginLeft: '125px', padding: '5px 20px' }}
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => {
                setcustomerPresentpopupFlag(false);
                setChangepopupFlag(true);
              }}
            >
              Yes
            </RACButton>
          </Grid>
          <Grid item md={4} className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: '70px', padding: '5px 20px' }}
              variant="contained"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => {
                btnNo();
              }}
            >
              No
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  /**RIS_PC_NO_45 The largepaymentAlertpopup() is rendered when largepayment AlertpopupFlag is updated as true */
  const largepaymentAlertpopup = () => {
    return (
      <div>
        <Grid className={classes2.textcenter}>
          {/* <AlertIcon ></AlertIcon> */}
          {/* <RACInfoIcon></RACInfoIcon> */}
          <Typography className={classes.racErrorFunction}>
            Do you wish to continue with Large Payment
          </Typography>
        </Grid>
        <Grid container style={{ marginLeft: '10px', marginTop: '40px' }}>
          <Grid item md={4} className={classes.floatLeft}>
            <RACButton
              style={{ marginLeft: '120px', padding: '10px' }}
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => yesBtn()}
            >
              Yes
            </RACButton>
          </Grid>
          <Grid item md={4} className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: '70px', padding: '10px' }}
              variant="contained"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => {
                setlargepaymentAlertpopupFlag(false);
              }}
            >
              No
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  /**RIS_PC_NO_45 yesBtn() function is triggered when co-worker clicks the yes button
   * in largepaymentAlertpopup */
  const yesBtn = () => {
    setlargepaymentAlertpopupFlag(false);
    setSecondFactorOpen(true);
    setRisFlag(true);
  };
  /**RIS_PC_NO_46 btnNo() function is triggered when co-worker clicks the no button
   * in customerPresentpopup */
  const btnNo = () => {
    setcustomerPresentpopupFlag(false);
    setload(true);
    // setChangepopupFlag(false)
    extensionArrayUrl = [];
    cofArrayUrl = [];
    MakepaymentApiCall();
  };
  /**RIS_PC_NO_46 The reasonpopup() is rendered when reasonpopupFlag updated as true */
  const reasonpopup = () => {
    console.log('rrr', reason);
    return (
      <div>
        <Grid
          // className={classes.textcenter}
          style={{
            marginLeft: '5%',
            // marginBottom:"1%"
          }}
        >
          {/* <AlertIcon ></AlertIcon> */}
          <Typography
            variant="h5"
            style={{
              // marginLeft:"-21%",
              marginBottom: '1%',
            }}
            className={`${classes.racErrorFunction}${classes.description}`}
          >
            Enter explanation for Large Payment exception.
          </Typography>
          <TextareaAutosize
            name="reason"
            minRows={5}
            maxLength={500}
            className={`${classes.textAreaN} `}
            value={reason.reason}
            onChange={(e: any) =>
              setReason({ ...reason, reason: e.target.value })
            }
          />
        </Grid>
        <Grid container style={{ marginLeft: '0px' }}>
          <Grid className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: '330px', padding: '6px 5%' }}
              variant="contained"
              color="primary"
              // disabled={continuebtnPopupFlag}
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => {
                reasonpopupContinueBtn();
              }}
            >
              Submit
            </RACButton>
          </Grid>
          <Grid item className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: '40px', padding: '6% 20%' }}
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => setReasonpopupFlag(false)}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  /**RIS_PC_NO_46 Triggered when click the submit button in reasonpoup() */
  const reasonpopupContinueBtn = () => {
    setReasonpopupFlag(false);
    setcustomerPresentpopupFlag(true);
    // MakepaymentApiCall()
  };

  {
    /**Rental Item Sale Ends here */
  }

  const OwnershipModal = () => {
    return (
      <Grid
        item
        data-testid="printOwnerId"
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
      >
        <Typography className={classes.mt3}>
          Certificate of Ownership
          <a
            href="#"
            style={{ marginLeft: '5px' }}
            onClick={() => ownershipurl()}
            data-testid="ViewClickId"
          >
            view
          </a>
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
        >
          <RACButton
            color="primary"
            variant="contained"
            className={classes.mx1}
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-testid="continueId"
            onClick={() => receipt()}
            data-bs-target="#delivery-receipt"
          >
            continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  if (redirectToAccountManagement) {
    const urlParams = new URLSearchParams(window.location.search);
    const retentionType = urlParams.get('CRTYPE');
    if (retentionType) {
      return (
        <Redirect
          to={{
            pathname: `/am/customer/${customer}`,
            state: {
              ...(location?.state || {}),
              redirectSource: 'payment',
            },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: AppRoute.AccountManagement.replace(
              ':customerId',
              location?.state?.accountManagementCustomerId|| customer || ''
            ),
            state: {
              ...(location?.state || {}),
              redirectSource: 'payment',
            },
          }}
        />
      );
    }
  }


  const storeExceptionCancelledFn = async () => {
    setStoreExceptionEnable(false);
    setStoreExceptionPayload([]);
    // setTwoFactorCancelClick(true)

  }
  const storeExceptionCompletedFn = async (updatedPayload?: any) => {
    if (EPOException == true) {
      if (freeDaysException == true) {
        EPOException = false
        setEPOException(EPOException)
        StoreExceptionEnable = true
        setStoreExceptionEnable(StoreExceptionEnable)
      }
      else {
        setload(true);
        if (props.isOtherStoreAgrSelected) {
          invokeAcceptPayAPIMultiStore(updatedPayload);
        } else {
          invokeAcceptPayAPISingleStore(updatedPayload);
        }
      }

    }
    else {
      setload(true);
      if (props.isOtherStoreAgrSelected) {
        invokeAcceptPayAPIMultiStore(updatedPayload);
      } else {
        invokeAcceptPayAPISingleStore(updatedPayload);
      }
    }

  }

  const getModuleName = () => {
    return (props?.selectedAgreements?.some((agr: any) => agr.employeePurchasePlan === true) ?
      SLA_MODULE_NAME.get('EMPLOYEE_PURCHASE_PLAN_SLA') : SLA_MODULE_NAME.get('ACCEPT_PAYMENT_SLA'));
  }

  const getStoreAccessResponse = () => {
    return (props?.selectedAgreements?.some((agr: any) => agr.employeePurchasePlan === true) ?
      undefined : profileAccResp );
  }

  return (
    <>
      <RACButton
        color="primary"
        variant="contained"
        className={classes.ms2}
        name="De_ActivateClubbtn"
        onClick={() => showPopup(true)}
        data-bs-toggle="modal"
        data-bs-target="#transaction"
        data-testid="acceptBtn"
        disabled={acceptdisable}
      >
        {' '}
        Accept Payment
      </RACButton>

      {SecondFactorOpen === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          setEmployeeDetails={setEmployeeDetails}
          storeAccessResponse = { getStoreAccessResponse() }
          CurrentRole={ props.currentRole }
          moduleName = { getModuleName() }
        />
      ) : null}
      {customerSignaturePopup ? (
        <CustomerSignature
          customerSignUsingField="club"
          customerSignUsingModule="payment"
          identifier={parseInt(customer)}
          customersArr={getCustDetail()}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={['CLUB']}
          customerSignatureCompletedFn={() => redirectionFunc()}
          customerSignatureCanceledFn={() => redirectionFunc()}
          documentGenerateCompleted={clubSignFlow}
        />
      ) : null}


      {StoreExceptionEnable ?
        (
          <StoreException
            isopen={StoreExceptionEnable}
            setStoreExceptionEnable={setStoreExceptionEnable}
            refCode={EPOException == true ? 'EPD' : 'REX'}
            identifierType={'RECEIPT'}
            exceptionDescscription={EPOException == false ? 'Rental Extension/Free Time' : null}
            storeExceptionCancelled={storeExceptionCancelledFn}
            storeExceptionCompleted={storeExceptionCompletedFn}
          />
        ) : null
      }
      {customerSignaturePopupInote ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="payment"
          identifier={pifAgreementID}
          customersArr={getCustDetail()}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={['INOTE']}
          customerSignatureCompletedFn={() => redirectionFunc()}
          customerSignatureCanceledFn={() => redirectionFunc()}
          documentGenerateCompleted={clubSignFlow}
        />
      ) : null}

      <RACModalCard
        isOpen={load}
        maxWidth="xs"
        onClose={() => setload(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopup()}
      />
      {/* spinner part end here */}

      {/* Transaction completed Modal Starts here */}

      <RACModalCard
        isOpen={tran && epayFail == false}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={transactioncompletedPopup()}
      />
      {/*Receipt*/}

      {/* Printing Modal Starts here */}
      <RACModalCard
        isOpen={recpt}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={Receiptpopup()}
      />
      <RACModalCard
        isOpen={recptCO}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={ReceiptpopupCO()}
      />
      {/* Printing ends here */}

      {/**Rental Item Sale Starts here */}
      <RACModalCard
        isOpen={largepaymentAlertpopupFlag}
        maxWidth="xs"
        onClose={() => setlargepaymentAlertpopupFlag(false)}
        closeIcon={false}
        borderRadius={'20px !important'}
        // eslint-disable-next-line react/no-children-prop
        children={largepaymentAlertpopup()}
      />

      <RACModalCard
        isOpen={reasonpopupFlag}
        maxWidth="sm"
        onClose={() => setReasonpopupFlag(false)}
        closeIcon={false}
        borderRadius={'20px !important'}
        // eslint-disable-next-line react/no-children-prop
        children={reasonpopup()}
      />

      <RACModalCard
        isOpen={customerPresentpopupFlag}
        maxWidth="xs"
        onClose={() => setcustomerPresentpopupFlag(false)}
        closeIcon={false}
        borderRadius={'20px !important'}
        // eslint-disable-next-line react/no-children-prop
        children={customerPresentpopup()}
      />
      <RACModalCard
        isOpen={changepopupFlag}
        maxWidth="xs"
        // onClose={() => setalertpopupFlag(false)}
        // closeIcon={false}
        borderRadius={'20px !important'}
        // eslint-disable-next-line react/no-children-prop
        children={changepopup()}
      />
      {/**Rental Item Sale ends here */}

      {/* Tray1 Modal Starts here */}
      <RACModalCard
        isOpen={Tray1}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={Tray1Popup()}
      />
      {/* Tray1 ends here */}

      {/* Certificate of ownership 2nd popup starts here */}
      <RACModalCard
        isOpen={Ownership}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={OwnershipModal()}
      />
      {/* Certificate of ownership 2nd popup end here */}

      <RACModalCard
        isOpen={TranFail}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={unAvailable()}
      />
      <RACModalCard
        isOpen={isAmountMismatch}
        maxWidth="xs"
        borderRadius={"20px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={renderAmountMismatch()}
      />
      {/* <RACModalCard
        isOpen={fetchReceipt}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={fetchReceiptPopUp()}
      /> */}
      <RACModalCard
        isOpen={epayFail}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={epayunAvailable()}
      />
      {/* <RACModal
      isOpen={viewReceiptPopup}
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="lg"
      maxHeight="lg"
      title={'Receipt'}
      content={
        <iframe
              ref={eventFrame}
              frameBorder={0}
              width="100%"
              height="100%"
              src={`${ReceiptURL}#toolbar=0`}
        ></iframe>
      }
      onClose={() => setviewReceiptPopup(false)}
      buttons={
        <>
          <RACButton variant="outlined" color="secondary" onClick={() => setviewReceiptPopup(false)}>
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={print}
          >
            Print
          </RACButton>
        </>
        
      }
    /> */}
      <RACModalCard
        isOpen={viewReceiptPopup}
        className={classes.customModal}
        maxWidth="lg"
        title={'Receipt'}
        children={receiptPopUp()}
        closeIcon={true}
        onClose={() => setviewReceiptPopup(false)}
      />

      <RACModalCard
        isOpen={showPaymentStatus}
        maxWidth="sm"
        title={'Transaction Status'}
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildPaymentStatus()}
      />

      <RACModalCard
        isOpen={showReceiptStatus}
        maxWidth="sm"
        title={'Receipt Status'}
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildReceiptStatus()}
      />

      <RACModalCard
        isOpen={viewReceiptPopupCO}
        className={classes.customModal}
        maxWidth="lg"
        title={'Receipt'}
        children={receiptPopUpCO()}
        closeIcon={true}
        onClose={() => setviewReceiptPopupCO(false)}
      />

      <RACModalCard
        isOpen={openSuspenseWithdraw}
        maxWidth="sm"
        borderRadius={'25px !important'}
        children={buildSuspenseWithdraw()}
      />

      <RACModalCard
        isOpen={sessionExpiredPopupFlag}
        maxWidth="xs"
        borderRadius={'25px !important'}
        children={sessionExpiredReloadPopup()}
      />

      {receipturl != undefined && cofArrayUrl.length > 0 ? (
        <>
          <RACModalCard
            isOpen={viewCOFPopup}
            className={classes.customModal}
            maxWidth="lg"
            title={'Certificate of Ownership'}
            children={viewCOF(cofIndex)}
            closeIcon={true}
            onClose={() => setviewCOFPopup(false)}
          />
        </>
      ) : null}
      <RACModalCard
        isOpen={openCommitmentConfirmation}
        maxWidth="sm"
        borderRadius={'25px !important'}
        children={commitmentConfirmation()}
      />
      {receipturl != undefined && extensionArrayUrl.length > 0 ? (
        <>
          <RACModalCard
            isOpen={viewExtPopup}
            className={classes.customModal}
            maxWidth="lg"
            title={'Extension Receipt'}
            children={viewExtReceipt(extIndex)}
            closeIcon={true}
            onClose={() => setviewExtPopup(false)}
          />
        </>
      ) : null}
{secondFactorAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setProceedButtonLoader(false);
            setFreeTimeAuthPopup(false);
            setSecondFactorAuth(false);
          }}
          setTwoFactorCompleted={() => {
            setProceedButtonLoader(false);
            setFreeTimeAuthPopup(false);
            setCappedFreeTimeAuthCompleted(false);
            setSecondFactorAuth(false);
            showPopup(false);
          }}
          moduleName={'CappedFreeTime'}
          CurrentRole={props.currentRole}
          setApprover={true}
        ></SecondFactor>
      ) : null}
      {freeTimeAuthPopup ? (
        <RACModalCard
          isOpen={freeTimeAuthPopup}
          closeIcon={false}
          borderRadius={"25px !important"}
          onClose={() => setFreeTimeAuthPopup(false)}
          maxWidth={"xs"}
          //eslint-disable-next-line react/no-children-prop
          children={freeTimeAuthPopupContent()}
        />
      ) : null}
      {autoPayConfirmationPopup ? (
        <RACModalCard
          isOpen={autoPayConfirmationPopup}
          closeIcon={false}
          borderRadius={"25px !important"}
          onClose={() => {
            setAutoPayConfirmationPopup(false);
            history.push(`/agreement/rental/delivery/${customer}/${Agreement}?exagreementid=${exchangeAgreementId}`)
          }}
          maxWidth={"sm"}
          //eslint-disable-next-line react/no-children-prop
          children={openAutoPayPopup()}
        />
      ) : null}
    </>
  );
}

export default ProcessPayment;
